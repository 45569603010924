import React, { useState } from "react";
import Modal from "react-modal";
import api from "../../../api";
import {
  DotLoader,
} from 'react-spinners';
import { CURRENT_WORKSPACE } from "../../../constants";

const UploadDesignsModal = ({ show, onHide, onHideCallback }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]);
  };


  
  const handleUpload = async () => {
    setLoading(true); // Set loading to true when upload starts

    if (!selectedFiles || selectedFiles.length === 0) {
      alert("Please select files to upload.");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i]);
    }

    // Add workspace_id to the FormData
    formData.append("workspace_id", localStorage.getItem(CURRENT_WORKSPACE));

    try {
      const response = await api.post("/workspace/upload_designs/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        handleModalClose();
      } else if (response.status === 403) {
        if (response.data.error === "Designs quota exceeded") {
          alert(
            "You have exceeded your designs quota for current workspace. Please delete some designs and try again."
          );
        } else if (response.data.error === "Space quota exceeded") {
          alert(
            "You have exceeded your storage space quota. Please delete some files and try again."
          );
        } else if (response.data.error === "AI tokens quota exceeded") {
          alert(
            "You have exceeded your AI tokens quota. Please upgrade your plan or try again later."
          );
        } else {
          alert(response.data.error); // Display the error message from the backend
        }
      } else {
        alert("An unexpected error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      if (error.response && error.response.status === 403) {
        if (error.response.data.error === "Designs quota exceeded") {
          alert(
            "You have exceeded your designs quota for current workspace. Please delete some designs and try again."
          );
        } else if (error.response.data.error === "Space quota exceeded") {
          alert(
            "You have exceeded your storage space quota. Please delete some files and try again."
          );
        } else if (error.response.data.error === "AI tokens quota exceeded") {
          alert(
            "You have exceeded your AI tokens quota. Please upgrade your plan or try again later."
          );
        } else {
          alert(error.response.data.error); // Display the error message from the backend
        }
      } else {
        alert("An error occurred during the upload. Please try again.");
      }
    } finally {
      setLoading(false); // Set loading to false when upload completes
      onHide();
    }
  };

  

  const handleModalClose = () => {
    setSelectedFiles([]); // Clear selected files when the modal is closed
    if (onHideCallback) {
      console.log("Running onHideCallback")
      onHideCallback();  // Call the callback function if provided
    }
  };

  return (
    <Modal
      isOpen={show}
      onHide={handleModalClose}
      contentLabel="Upload Designs"
      className="Modal"
      shouldCloseOnOverlayClick={true}
    >
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}>
          <DotLoader size={100} color="#6D85EC" />
        </div>
      )}

      <div className="fixed pin z-50 overflow-auto bg-smoke-light flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-yellow-200 rounded-lg shadow dark:bg-gray-700">
            <div className="p-4">
              <h2 className="text-lg font-bold mb-4">Upload Designs</h2>
              <input
                type="file"
                onChange={handleFileChange}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 mb-4"
                multiple
              />
              <div className="flex justify-end">
                <button
                  onClick={handleUpload}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2"
                >
                  Upload
                </button>
                <button
                  onClick={handleModalClose}
                  className="text-gray-700 bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadDesignsModal;
