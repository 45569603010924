import React, { useState } from "react";
import Modal from "react-modal";
import api from "../../../api";
import axios from "axios";

import {
  DotLoader,
} from 'react-spinners';

const UploadTemplatesModal = ({ isOpen, onRequestClose, onModalClose }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (selectedFiles.length === 0) {
      console.error("No files selected for upload");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i]);
    }

    try {
      const response = await api.post("/mockups_app/upload_mockup_templates/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        },
      });
      alert("Templates uploaded successfully");
      setSelectedFiles([]); // Clear selected files after successful upload
      onRequestClose();
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setLoading(false);
      setUploadProgress(0); // Reset progress
    }
  };

  const handleModalClose = () => {
    setSelectedFiles([]); // Clear selected files when the modal is closed
    onModalClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel="Upload Templates Modal"
      className="Modal"
      shouldCloseOnOverlayClick={true}
    >
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}>
          <DotLoader size={100} color="#6D85EC" />
        </div>
      )}

      <div className="fixed pin z-50 overflow-auto bg-smoke-light flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-yellow-200 rounded-lg shadow dark:bg-gray-700">
            <div className="p-4">
              <h2 className="text-lg font-bold mb-4">Upload Templates</h2>
              <input
                type="file"
                onChange={handleFileChange}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 mb-4"
                multiple
              />
              {loading && (
                <div className="w-full bg-gray-200 rounded-full">
                  <div
                    className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                    style={{ width: `${uploadProgress}%` }}
                  >
                    {uploadProgress}%
                  </div>
                </div>
              )}
              <div className="flex justify-end">
                <button
                  onClick={handleSubmit}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2"
                >
                  Upload
                </button>
                <button
                  onClick={handleModalClose}
                  className="text-gray-700 bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadTemplatesModal;
