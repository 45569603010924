import React, { useState, useEffect } from "react";
import api from "../../../api";
import {
  PRINTIFY_API_KEY,
  PRINTIFY_SHOP_ID,
  CURRENT_WORKSPACE,
} from "../../../constants";

const PrintifyShopModal = ({ closeModal }) => {
  const [apiKey, setApiKey] = useState("");
  const [printifyProductID, setPrintifyProductID] = useState("");
  const [shopData, setShopData] = useState([]);
  const [selectedShopID, setSelectedShopID] = useState([]);

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const savePrintifyInformation = async () => {
    try {
      localStorage.setItem(PRINTIFY_SHOP_ID, selectedShopID)
      localStorage.setItem(PRINTIFY_API_KEY, apiKey)

      const response = await api.post("/printify_app/save_printify_template/", {
        workspace_id: localStorage.getItem(CURRENT_WORKSPACE),
        shop_id: localStorage.getItem(PRINTIFY_SHOP_ID),
        apiKey: localStorage.getItem(PRINTIFY_API_KEY),
        printify_product_id: printifyProductID,
      });
      alert("Selected product saved as reference");
      closeModal();
    } catch (error) {
      console.error("Error selecting sample listing:", error);
      alert("Unable to validate the product, Check if the Product ID is Correct.")
    }
  };

  const fetchData = async () => {
    try {
      const response = await api.get("/printify_app/fetch_workspace_printify_info/", {
        params: {
          workspace_id: localStorage.getItem(CURRENT_WORKSPACE)
        }
      });

      setApiKey(response.data[0]["api_key"]);
      setPrintifyProductID(response.data[0]["product_id"]);
      setSelectedShopID(response.data[0]["shop_id"]);

      localStorage.setItem(PRINTIFY_SHOP_ID, selectedShopID)
      localStorage.setItem(PRINTIFY_API_KEY, apiKey)

  } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchShopData = async () => {
    try {
      localStorage.setItem(PRINTIFY_API_KEY, apiKey);
      const response = await api.post("/printify_app/fetch_printify_shopIDs/", {
        apiKey: localStorage.getItem(PRINTIFY_API_KEY),
      });
      setShopData(response.data.shops);
      alert("Select Shop from the dropdown!!")
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOutsideClick}>
      <div className="modal">
        <button className="close-button" onClick={closeModal}>
          &times;
        </button>
        <div className="modal-content">
          <p className="title-font font-medium text-xl text-gray-900">
            Connect to Printify Store
          </p>
          <div className=" max-w-lg">
          {/* <p className="title-font text-sm text-gray-900">
          <a href="link" target="_blank" rel="noopener noreferrer">Click here </a> for detailed instructions on how to get relevant information from your Printify store.
          </p> */}
          <p className="title-font font-medium text-sm text-gray-900">
            Steps to fetch API key:
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            1. Open your account on Printify
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            2. Navigate to your store
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            3. Go to "Account" at bottom left, and click on "Connections"
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            4. Generate a New Token
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            5. Copy the token and paste in below text box.
          </p>          
          <p className="title-font font-medium text-sm text-gray-900">
            6. Click on "Fetch Shops".
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            7. Click on "Select Shop" dropdown and choose your shop.
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            8. Create or identify an existing product that can be used as base to create new listings. Open the product on Printify and get the Product ID from URL. It will be a 24-character alphanumeric string (e.g. 65f5c4546cd65e8xef0e4e34). 
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            9. Paste  the product ID in text box. 
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            10. Click on 'Validate & Save" button'. Click anywhere on the screen to close the window.  
          </p>
            {/* Input for API key */}
            <textarea
              id="api_key"
              rows="3"
              name="product_title"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 resize-none"
              placeholder="Your API key..."
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />

            {/* Button to fetch shop IDs */}
            <button
              type="button"
              className="text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
              onClick={fetchShopData}
              disabled={!apiKey}
            >
              Fetch Shops
            </button>
            {/* Dropdown to select shop ID */}
            <select
              value={selectedShopID}
              onChange={(e) => setSelectedShopID(e.target.value)}
              className="block resize-none p-2.5 w-full mt-4 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">Select Shop ID</option>
              {shopData.map((shop) => (
                <option key={shop.id} value={shop.id}>
                  {shop.title}
                </option>
              ))}
            </select>
            {/* Input for Printify Product ID */}
            <input
              type="text"
              id="product_template_key"
              rows="1"
              value={printifyProductID}
              name="printify_product_id"
              className="block resize-none p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Printify Product ID"
              onChange={(e) => setPrintifyProductID(e.target.value)}
            />
            {/* Button to select sample listing */}
            <button
              type="button"
              className="text-center py-3 px-4 mt-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
              onClick={savePrintifyInformation}
            >
              Validate & Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintifyShopModal;
