import React, { useState, useEffect } from "react";
import api from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import WorkflowNavigation from "./blocks/WorkflowNavigation";
import DeleteModal from "./blocks/DeleteModal";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CURRENT_USER,
  CURRENT_WORKSPACE,
  PRINTIFY_API_KEY,
  PRINTIFY_SHOP_ID,
  SERVER_BASE_URL,
} from "../../constants";

import ShopifyShopModal from "./blocks/ShopifyShopModal"; // Import your modal component

import {
  ClipLoader,
  BarLoader,
  BeatLoader,
  BounceLoader,
  CircleLoader,
  DotLoader,
  RingLoader,
} from "react-spinners";

export default function PublishListings() {
  const [workarea_information, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Add state for loading
  const navigate = useNavigate();

  const location = useLocation();
  const workspace_id = localStorage.getItem(CURRENT_WORKSPACE);

  // Function to handle opening modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.get("/workspace/get_workarea_information/", {
        params: { workspace_id: workspace_id },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const createPrintifyListings = async () => {
    try {
      setLoading(true); // Set loading to true when upload starts

      const response = await api.post(
        "/printify_app/create_printify_listings_v2/",
        {
          workspace_id: localStorage.getItem(CURRENT_WORKSPACE),
        }
      );
      alert("Listings published on Printify");
      fetchData();
    } catch (error) {
      console.error("Error selecting sample listing:", error);
    } finally {
      setLoading(false); // Set loading to false when upload completes
    }
  };

  const syncPrintifyShopifyMetadata = async () => {
    try {
      setLoading(true); // Set loading to true when upload starts

      const response = await api.post(
        "/printify_app/sync_printify_shopify_metadata/",
        {
          shop_id: localStorage.getItem(PRINTIFY_SHOP_ID),
          apiKey: localStorage.getItem(PRINTIFY_API_KEY),
          workspace_id: localStorage.getItem(CURRENT_WORKSPACE),
        }
      );
      alert("Listings synced with mockup images.");
      fetchData();
    } catch (error) {
      console.error("Error selecting sample listing:", error);
    } finally {
      setLoading(false); // Set loading to false when upload completes
    }
  };

  const publishPrintifyListings = async () => {
    try {
      const response = await api.post(
        "/printify_app/publish_printify_listings/",
        {
          shop_id: localStorage.getItem(PRINTIFY_SHOP_ID),
          apiKey: localStorage.getItem(PRINTIFY_API_KEY),
          workspace_id: localStorage.getItem(CURRENT_WORKSPACE),
        }
      );
      alert("Listings created on Printify");
      fetchData();
    } catch (error) {
      console.error("Error selecting sample listing:", error);
    }
  };

  const handleClick = () => {
    const url = `/publishListings`;
    navigate(url);
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <DotLoader size={100} color="#6D85EC" />
        </div>
      )}

      <div class="flex flex-col h-screen">
        <div class="w-full flex items-center justify-center">
          <p class="p-5 text-4xl font-semibold">Publish Listings</p>
        </div>

        <div class="px-8 bg-gray-100 w-full flex flex-row items-start ">
          <div class="flex items-left justify-left w-full ">
            <div class="text-left w-full">
              <ul class="relative flex flex-col md:flex-row gap-2 w-full">
                <li class="md:shrink md:basis-0 justify-between flex-1 group flex gap-x-2 md:block w-full">
                  <div class="grow md:grow-0 md:mt-3 pb-5 flex items-center gap-4 w-full">
                    <div class="text-sm text-gray-900 dark:text-neutral-900 w-full">
                      <p>
                        Publish your listings
                        <ul class="list-disc pl-5">
                          <li>
                            1. <span class="text-red-500">IMPORTANT: </span>
                            Select the newly created listings on Printify and
                            publish them manually.
                          </li>
                          <li>2. Connect your Shopify Store.</li>
                          <li>
                            3. Click this button to sync published listings and
                            upload our mockups.
                          </li>
                          <li>
                            4. Visit your Shopify store and verify your
                            listings.
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div class="w-full flex flex-col md:flex-row gap-2">
                      <button
                        type="button"
                        class="text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none w-full md:w-auto"
                        onClick={openModal}
                      >
                        2. Connect Shopify Store
                      </button>

                      <button
                        type="button"
                        class="text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none w-full md:w-auto"
                        onClick={syncPrintifyShopifyMetadata}
                      >
                        3. Sync Listings Metadata
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <table class="min-w-full divide-y-2 bg-white text-sm">
          <thead class="ltr:text-left rtl:text-right bg-gray-900 text-white">
            <tr>
              <th class="px-4 py-2 font-medium w-2/12">Design Images</th>
              <th class="px-4 py-2 font-medium w-5/12">Product Title & Tags</th>
              <th class="px-4 py-2 font-medium w-5/12">Product Description</th>
            </tr>
          </thead>
          <tbody class="divide-y">
            {workarea_information.map((item, index) => (
              <tr class="border-b border-gray-900">
                <td class="px-4 py-2 font-medium text-gray-900">
                  <div class="flex items-center">
                    <div class="relative inline-block shrink-0 rounded-2xl me-3"></div>
                    <div class="relative inline-block shrink-0 rounded-2xl me-3">
                      <span>{index + 1}</span>
                    </div>
                    <div class="relative inline-block shrink-0 rounded-2xl me-3">
                      <img
                        src={item.image_thumbnail_path}
                        class="w-full h-[250px] inline-block shrink-0 rounded-2xl"
                        alt=""
                      />
                    </div>
                  </div>
                </td>
                <td class="px-4 py-2 text-gray-700">
                  <span class="text-left text-md font-semibold">
                    Printify Listing ID:{" "}
                  </span>
                  <span class="text-left text-md break-words">
                    {item.printify_product_id}
                  </span>
                  <hr></hr>
                  <span class="text-left text-md font-semibold">
                    Shopify Listing ID:{" "}
                  </span>
                  <span class="text-left text-md break-words">
                    {item.shopify_product_id}
                  </span>
                  <hr></hr>
                  <span class="text-left text-md font-semibold">Title: </span>
                  <span class="text-left text-md break-words">
                    {item.product_title}
                  </span>
                  <hr></hr>
                  <span class="text-left text-md font-semibold">
                    Keywords:{" "}
                  </span>
                  <span class="text-left text-md break-words">
                    {item.seo_keywords}
                  </span>
                </td>
                <td class="px-4 py-2 text-gray-700">
                  <span class="text-left text-md font-semibold">
                    Description:{" "}
                  </span>
                  <span class="text-left text-md break-words">
                    {item.product_description}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Render modal component conditionally */}
        {isModalOpen && (
          <ShopifyShopModal closeModal={closeModal}>
            {/* Content of the modal goes here */}
            <p>This is the modal content.</p>
          </ShopifyShopModal>
        )}
      </div>
    </div>
  );
}
