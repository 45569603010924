/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
// import Logo from "./Assets/Logo.svg";
import { BsCart2 } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import { isAuthenticated } from "./blocks/auth";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CURRENT_USER,
  CURRENT_WORKSPACE,
  SERVER_BASE_URL,
} from "./../../constants";
// import Logo from "./Assets/Logo.png"
import Logo from "./Assets/Logo";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setAuth(isAuthenticated());
  }, []);

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    setAuth(false);
    navigate("/login");
  };

  return (
    <div className="w-full bg-white shadow-md z-50 sticky top-0">
      <div className="container mx-auto flex justify-between items-center p-4">
        <a href="/">
          <Logo />
        </a>
        <div className="navbar-links-container flex space-x-8  ">
          <a href="/">Home</a>
          <a href="/resources">Resources</a>
          <a href="/contactus">Contact Us</a>

          {auth ? (
            <>
              <a href="/account">Your Account</a>
              <a href="#" onClick={handleLogout}>
                Logout
              </a>
              <a
                href="/welcome"
                className=" bg-indigo-700 hover:bg-indigo-900  px-2 rounded"
              >
                <span class="text-white font-bold">Your Workspaces</span>
              </a>
            </>
          ) : (
            <>
              <a href="/login">Login</a>
              <a href="/register">Register</a>
            </>
          )}
        </div>
        {/*     <div className="navbar-menu-container">
      <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
    </div> */}
      </div>
      {/*   <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => setOpenMenu(false)}
      onKeyDown={() => setOpenMenu(false)}
    >
      <List>
        {menuOptions.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  </Drawer> */}
    </div>
  );
};

export default Navbar;
