import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CURRENT_USER,
  CURRENT_WORKSPACE,
  SERVER_BASE_URL,
} from "../../constants";
import api from "../../api";
import WorkflowNavigation from "./blocks/WorkflowNavigation";
// import WorkspaceModal from './blocks/WorkspaceModal'; // Adjust the import path as necessary
import {
  RiCheckboxCircleLine,
  RiDeleteBin2Line,
  RiCheckboxBlankCircleLine,
} from "react-icons/ri";
import DeleteModal from "./blocks/DeleteModal"; // Import the modal component
import UploadDesignsModal from "./blocks/UploadDesignsModal";
import {
  ClipLoader,
  BarLoader,
  BeatLoader,
  BounceLoader,
  CircleLoader,
  DotLoader,
  RingLoader,
} from "react-spinners";

export default function PrepareDesigns() {
  const [workarea_information, setData] = useState([]);
  const [templates_information, setTemplatesData] = useState([]);
  const [loading, setLoading] = useState(false); // Add state for loading
  const [selectedForDelete, setSelectedForDelete] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);


  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    fetchData();
  };


  const handleSelectAll = () => {
    if (selectedForDelete.length === workarea_information.length) {
      // Deselect all
      setSelectedForDelete([]);
    } else {
      // Select all
      const allIds = workarea_information.map((item) => item.lineitem_id);
      setSelectedForDelete(allIds);
    }
  };

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      if (!selectedForDelete.includes(item.lineitem_id)) {
        setSelectedForDelete([...selectedForDelete, item.lineitem_id]);
      }
    } else {
      setSelectedForDelete(
        selectedForDelete.filter((id) => id !== item.lineitem_id)
      );
    }
  };

  const handleDelete = async () => {
    try {
      const lineitemIds = selectedForDelete.map((file) => file.lineitem_id);

      const response = await api.post("/workspace/delete_designs/", {
        lineitem_ids: selectedForDelete,
      });
      fetchData();
      setIsDeleteModalOpen(false);
      // Optionally, update the state to remove deleted files from the list
      setSelectedFiles(
        selectedFiles.filter((file) => !selectedForDelete.includes(file))
      );
      setSelectedForDelete([]);
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  const workspace_id = localStorage.getItem(CURRENT_WORKSPACE);

  const [selectedFiles, setSelectedFiles] = useState(null);

  const fetchData = async () => {
    try {


      const response = await api.get("/workspace/get_workarea_information/", {
        params: { workspace_id: workspace_id },
      });

      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  

  const handleClick = () => {
    const url = `/updateMetadata`;
    navigate(url);
  };

  return (
    <div>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
      />
<UploadDesignsModal
  show={showModal}
  onHide={() => setShowModal(false)}
  onHideCallback={fetchData}
/>


      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <DotLoader size={100} color="#6D85EC" />
        </div>
      )}

      <div class="flex flex-col h-screen">
        <div class=" w-full  flex items-center justify-center">
          <p class="p-5 text-4xl font-semibold">Upload Designs</p>
        </div>

        <div class="px-8 bg-gray-100 w-full  flex flex-row items-start ">
          <div class=" flex items-left justify-left w-full ">
            <div className="text-left">
              <ul class="relative flex flex-col md:flex-row gap-2">
                <li class="md:shrink md:basis-0 justify-between flex-1 group flex gap-x-2 md:block">
                  <div class="grow md:grow-0 md:mt-3 pb-5 flex items-center gap-4">
                    <p class="text-sm text-gray-900 dark:text-neutral-900 w-2/5">
                      Upload your designs for this workspace. Ensure that the
                      files are sized according to the templates and have
                      transparent background.
                    </p>

                    <button
                      type="button"
                      class=" text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent  bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={openModal}
                    >
                      Upload
                    </button>



                    <button
                      type="button"
                      className="text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gray-600 text-white hover:bg-gray-800 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={handleSelectAll}
                    >
                      {selectedForDelete.length ===
                      workarea_information.length ? (
                        <RiCheckboxBlankCircleLine
                          style={{ fontSize: "16px" }}
                        />
                      ) : (
                        <RiCheckboxCircleLine style={{ fontSize: "16px" }} />
                      )}
                      {selectedForDelete.length === workarea_information.length
                        ? "Deselect All"
                        : "Select All"}
                    </button>
                    <button
                      type="button"
                      className="text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={() => setIsDeleteModalOpen(true)}
                    >
                      <RiDeleteBin2Line style={{ fontSize: "16px" }} /> Delete
                    </button>

                    <button
                      type="button"
                      class=" text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-yellow-400 text-gray-900 hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={() => handleClick()}
                    >
                      Next
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-5 gap-4 p-4 text-center items-center justify-center ">
          {workarea_information.length > 0 ? (
            workarea_information.map((item, index) => (
              <label key={index} class="cursor-pointer">
                <div
                  key={item.lineitem_id}
                  class="shadow-lg flex flex-col items-center justify-center bg-gray-100"
                >
                  <div class="w-8/12 h-auto relative overflow-hidden rounded-lg max-w-xs group ">
                    <img
                      class="mx-auto w-full h-auto"
                      src={item.image_thumbnail_path}
                      alt=""
                    />
                  </div>

                  <input
                    type="checkbox"
                    checked={selectedForDelete.includes(item.lineitem_id)}
                    onChange={(e) => handleCheckboxChange(e, item)}
                  />
                  <span class="text-sm text-gray-900 dark:text-neutral-900">
                    {item.image_file_name}
                  </span>
                </div>
              </label>
            ))
          ) : (
            <p>No designs available</p>
          )}
        </div>
      </div>
    </div>
  );
}
