// src/components/TutorialList.js

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GiBookshelf } from "react-icons/gi";
import Navbar from "./Navbar";
import Footer from "./Footer";

const TutorialList = () => {
  const [tutorials, setTutorials] = useState([]);

  useEffect(() => {
    fetch("/tutorials/tutorials.json")
      .then((response) => response.json())
      .then((data) => setTutorials(data))
      .catch((error) => console.error("Error fetching tutorials:", error));
  }, []);

  return (
    <div>
      <Navbar />
      
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-12 mx-auto">
          <div className="flex flex-col text-center w-full">
            <div className="flex items-center justify-center">
              <GiBookshelf size={128} color="black" />
            </div>
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Resources
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Explore a comprehensive collection of knowledge documents, guiding
              users through every aspect of the platform's functionalities and
              features in the Resources section.
            </p>
          </div>
        </div>
      </section>

      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-12 mx-auto">
          <div className="flex flex-wrap -m-4">
            {tutorials.map((tutorial) => (
              <div key={tutorial.id} className="p-4 md:w-1/3 w-full">
                <Link to={`/tutorial/${tutorial.file}`}>
                  <article className="overflow-hidden rounded-lg shadow transition hover:shadow-lg flex flex-col h-full">
                    <img
                      alt=""
                      src="https://images.unsplash.com/photo-1481627834876-b7833e8f5570?q=80&w=2456&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      className="h-56 w-full object-cover"
                    />
                    <div className="bg-white p-4 sm:p-6 flex-grow">
                      <time dateTime="2022-10-10" className="block text-xs text-gray-500">
                        {tutorial.date_published}
                      </time>
                      <a href="#">
                        <h3 className="mt-0.5 text-lg text-gray-900">{tutorial.title}</h3>
                      </a>
                      <p className="mt-2 line-clamp-3 text-sm/relaxed text-gray-500">
                        {tutorial.description}
                      </p>
                    </div>
                  </article>
                </Link>
  

                



              </div>
            ))}
          </div>
        </div>
      </section>
      <span
            className="w-full text-left text-black p-2"
            style={{ fontSize: "0.8rem" }}
          >
            Credits: 
            <ul className="text-black p-2">
              <li>                            Photo by <a href="https://unsplash.com/@itfeelslikefilm?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">🇸🇮 Janko Ferlič</a> on <a href="https://unsplash.com/photos/photo-of-library-with-turned-on-lights-sfL_QOnmy00?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
              </li>
            </ul>

          </span>

      <Footer />
    </div>
  );
};

export default TutorialList;
