import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';  

const Tutorial = () => {
  const { file } = useParams();
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(`/tutorials/${file}`);
        if (response.ok) {
          const text = await response.text();
          setContent(text);
        } else {
          throw new Error('File not found');
        }
      } catch (error) {
        console.error('Failed to fetch tutorial:', error);
        setContent('<h1>Error loading tutorial.</h1>');
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, [file]);

  return (
    <div className="tutorial-content">

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {parse(content)}  {/* Parse and render HTML content */}
        </div>
      )}
    </div>
  );
};

export default Tutorial;
