import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CURRENT_USER,
  CURRENT_WORKSPACE,
  SERVER_BASE_URL,
} from "../../constants";
import api from "../../api";
import UploadTemplatesModal from "./blocks/UploadTemplatesModal";

import {
  ClipLoader,
  BarLoader,
  BeatLoader,
  BounceLoader,
  CircleLoader,
  DotLoader,
  RingLoader,
} from "react-spinners";

export default function SelectMockupTemplates() {
  const [templates_information, setTemplatesData] = useState([]);
  const [workspace_templates_information, setWorkspaceTemplatesData] = useState(
    []
  );
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [removeTemplates, setRemoveTemplates] = useState([]);
  const [activeTab, setActiveTab] = useState(0); // New state for active tab
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Add state for loading
  const navigate = useNavigate();

  useEffect(() => {
    fetchWorkspaceTemplatesData();
    fetchData();
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    fetchData();
  };

  const fetchData = async () => {
    setLoading(true); // Set loading to true when upload starts
    try {
      const response = await api.get(
        "/mockups_app/get_templates_information/",
        { params: { workspace_id: localStorage.getItem(CURRENT_WORKSPACE) } }
      );
      setTemplatesData(response.data.templates_information);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to true when upload starts
    }
  };

  const fetchWorkspaceTemplatesData = async () => {
    try {
      const response = await api.get(
        "/mockups_app/get_workspace_templates_information/",
        { params: { workspace_id: localStorage.getItem(CURRENT_WORKSPACE) } }
      );
      setWorkspaceTemplatesData(response.data.templates_information);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCheckboxChange = (templateId) => {
    const isSelected = selectedTemplates.includes(templateId);
    if (isSelected) {
      setSelectedTemplates(selectedTemplates.filter((id) => id !== templateId));
    } else {
      setSelectedTemplates([...selectedTemplates, templateId]);
    }
  };

  const handleRemoveCheckboxChange = (templateId) => {
    const isSelected = removeTemplates.includes(templateId);
    if (isSelected) {
      setRemoveTemplates(removeTemplates.filter((id) => id !== templateId));
    } else {
      setRemoveTemplates([...removeTemplates, templateId]);
    }
  };

  const handleSubmit = async () => {
    setLoading(true); // Set loading to true when upload starts
    try {
      if (selectedTemplates.length > 0) {
        const response = await api.post(
          "/mockups_app/set_templates_for_workspace/",
          {
            selectedTemplates: selectedTemplates,
            workspace_id: localStorage.getItem(CURRENT_WORKSPACE),
          }
        );
        fetchWorkspaceTemplatesData();
        fetchData();
      }
    } catch (error) {
      console.error("Error sending selected templates:", error);
    } finally {
      setLoading(false); // Set loading to true when upload starts
    }
  };

  const handleRemoveSubmit = async () => {
    try {
      if (removeTemplates.length > 0) {
        const response = await api.post(
          "/mockups_app/remove_templates_from_workspace/",
          {
            removeTemplates: removeTemplates,
            workspace_id: localStorage.getItem(CURRENT_WORKSPACE),
          }
        );
        fetchData();
        fetchWorkspaceTemplatesData();
      }
    } catch (error) {
      console.error("Error sending selected templates:", error);
    }
  };

  const deleteTemplate = async () => {
    try {
      if (selectedTemplates.length > 0) {
        const response = await api.post("/mockups_app/delete_user_templates/", {
          removeTemplates: selectedTemplates,
          workspace_id: localStorage.getItem(CURRENT_WORKSPACE),
        });
        fetchData();
        fetchWorkspaceTemplatesData();
      }
    } catch (error) {
      console.error("Error sending selected templates:", error);
    }
  };

  const handleClick = () => {
    const url = `/generateMockups`;
    navigate(url);
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <DotLoader size={100} color="#6D85EC" />
        </div>
      )}

      <div className="flex flex-col h-screen">
        <div className="w-full flex items-center justify-center">
          <p className="p-5 text-4xl font-semibold">Select Mockup templates</p>
        </div>

        <div className="px-8 bg-gray-100 w-full flex flex-row items-start">
          <div className="flex items-left justify-left w-full">
            <div className="text-left">
              <ul className="relative flex flex-col md:flex-row gap-2">
                <li className="md:shrink md:basis-0 justify-between flex-1 group flex gap-x-2 md:block">
                  <div className="grow md:grow-0 md:mt-3 pb-5 flex items-center gap-4">
                    <p className="text-sm text-gray-900 dark:text-neutral-900">
                      Upload your designs for this workspace. Ensure that the
                      files are sized according to the templates and have
                      transparent background.
                    </p>

                    <button
                      type="button"
                      class=" text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent  bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={openModal}
                    >
                      Upload
                    </button>
                    <button
                      type="button"
                      className="text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={() => deleteTemplate()}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      class=" text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-yellow-400 text-gray-900 hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={() => handleClick()}
                    >
                      Next
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="w-full flex items-center justify-center p-5 ">
          <div className="flex">
            <div className="w-1/2 p-4 items-center text-center drop-shadow-md bg-gray-100">
              <p className="title-font font-medium text-xl text-gray-900">
                Remaining Templates
              </p>
              <UploadTemplatesModal
                isOpen={isOpen}
                onRequestClose={closeModal}
              />

              <div className="grid grid-cols-4 gap-4 p-4 text-center bg-white">
                {templates_information.length > 0 ? (
                  templates_information.map((template) => (
                    <div key={template.mkp_template_id}>
                      <div className="flex-shrink-0 relative overflow-hidden rounded-lg max-w-xs group">
                        <img src={template.thumbnail_image_path} alt="" />
                      </div>
                      <input
                        id={template.mkp_template_id}
                        type="checkbox"
                        checked={selectedTemplates.includes(
                          template.mkp_template_id
                        )}
                        onChange={() =>
                          handleCheckboxChange(template.mkp_template_id)
                        }
                      />
                      <h2 className="title-font font-medium text-xs text-gray-900">
                        {template.user_file_name}
                      </h2>
                    </div>
                  ))
                ) : (
                  <p>No templates selected</p>
                )}
              </div>
            </div>

            <div className="w-10% p-4 text-center content-center">
              <div className="mb-4">
                <button
                  onClick={handleSubmit}
                  disabled={selectedTemplates.length === 0}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  &gt;&gt;
                </button>
              </div>
              <div>
                <button
                  onClick={handleRemoveSubmit}
                  disabled={removeTemplates.length === 0}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  &lt;&lt;
                </button>
              </div>
            </div>

            <div className="w-1/2 p-4 items-center text-center drop-shadow-md bg-gray-100">
              <p className="title-font font-medium text-xl text-gray-900">
                Selected Templates
              </p>
              <div className="grid grid-cols-4 gap-4 p-4 text-center bg-white">
                {workspace_templates_information.length > 0 ? (
                  workspace_templates_information.map((template) => (
                    <div key={template.mkp_template_id}>
                      <div className="flex-shrink-0 relative overflow-hidden rounded-lg max-w-xs group">
                        <img src={template.thumbnail_image_path} alt="" />
                      </div>
                      <input
                        id={template.mkp_template_id}
                        type="checkbox"
                        checked={removeTemplates.includes(
                          template.mkp_template_id
                        )}
                        onChange={() =>
                          handleRemoveCheckboxChange(template.mkp_template_id)
                        }
                      />
                      <h2 className="title-font font-medium text-xs text-gray-900">
                        {template.user_file_name}
                      </h2>
                    </div>
                  ))
                ) : (
                  <p>No templates selected</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
