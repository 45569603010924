import React from "react";
import ProfilePic from "./Assets/john-doe-image.png";
import { AiFillStar } from "react-icons/ai";
import BannerBackground from "./Assets/home-banner-background.png";
import BannerImage from "./Assets/home-banner-image.png";
import WorkflowImage from "./Assets/Workflow.png";
import { GoVideo } from "react-icons/go";
import { PiVideoFill } from "react-icons/pi";
import { IoLogoYoutube } from "react-icons/io";

const Hero = () => {
  return (
    <section class="pt-12 bg-gray-50 sm:pt-16">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="text-center ">
          <p class="max-w-4xl mx-auto mb-4 text-4xl font-bold leading-tight text-gray-900 sm:leading-tight sm:text-5xl lg:text-6xl lg:leading-tight">
            AI-Powered Print On Demand Listings Robot
          </p>
          <img
            class="px-4 w-1/2 mx-auto opacity-90"
            src="https://images.unsplash.com/photo-1716436329478-e4261139e1ca?q=80&w=2456&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          />

          <span
            className="w-full text-left text-black p-2"
            style={{ fontSize: "0.5rem" }}
          >
            Photo by{" "}
            <a href="https://unsplash.com/@boliviainteligente?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
              BoliviaInteligente
            </a>{" "}
            on{" "}
            <a href="https://unsplash.com/photos/a-close-up-of-a-computer-processor-with-many-components-frbBBb2l2SI?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
              Unsplash
            </a>
          </span>

          <h1 class="max-w-2xl mx-auto px-6 text-lg text-gray-600 font-inter">
            Streamline your print on demand business with our all-in-one
            workflow automation platform. From AI-generated SEO content to
            product mockups and seamless listing on sales platforms, we've got
            everything you need to succeed.
          </h1>
          <div class="px-8 sm:items-start sm:justify-center sm:px-0 sm:space-x-5 sm:flex mt-9">
            <a
              href="/register"
              title=""
              class="mb-3 sm:mb-0 inline-flex items-center justify-center w-full px-14 py-3 text-lg font-bold text-white transition-all duration-200 bg-gray-900 border-2 border-transparent sm:w-auto rounded-xl hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              role="button"
            >
              Try for Free
            </a>
            <a
              href="https://youtu.be/vKnOaa3Vwok"
              class="inline-flex items-center justify-center w-full px-8 py-3 text-lg font-bold text-gray-900 hover:text-white transition-all duration-200 bg-gray-100 border-2 border-gray-900 sm:w-auto rounded-xl hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              role="button"
            >
              <IoLogoYoutube size={24} color="red" /> <span className="px-2"> Watch Demo</span>
            </a>
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div class="relative mx-auto mt-4 md:mt-8">
          <div class="lg:max-w-4xl lg:mx-auto flex justify-center items-center">
            {/* <img class="px-4 md:mpx-8" src="https://images.unsplash.com/photo-1628277613967-6abca504d0ac" /> */}
            {/* <PiVideoFill size={128} color="#ab1818" /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
