// Modal.js
import React, { useState } from "react";
import Modal from "react-modal";

const CreateWorkspaceModal = ({ isOpen, onRequestClose, onSubmit }) => {
  const [inputValue, setInputValue] = useState("");

  const handleSubmit = () => {
    // Call the onSubmit callback with the text value
    onSubmit(inputValue);
    // Clear the text input
    setInputValue("");
    // Close the modal
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Text Modal"
      className="Modal"
      shouldCloseOnOverlayClick={true}
    >
      <div
        tabindex="-1"
        aria-hidden="true"
        class="fixed pin overflow-auto bg-smoke-light flex overflow-y-auto overflow-x-hidden top-0 right-0 left-0  justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div class="relative p-4 w-full max-w-md max-h-full">
          <div class="relative bg-yellow-200 rounded-lg shadow dark:bg-gray-700">
            <div class="p-4">
              <h2 className="text-lg font-bold mb-4">Workspace Name</h2>
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 mb-4"
              />
              <div className="flex justify-end">
                <button
                  onClick={handleSubmit}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2"
                >
                  Create Workspace
                </button>
                <button
                  onClick={onRequestClose}
                  className="text-gray-700 bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateWorkspaceModal;
