import React, { useState, useEffect } from 'react';
import CheckoutButton from './CheckoutButton';
import api from "../../../api";

const pricingData = [
  {
    title: 'Starter',
    price: 'Free',
    features: [
      '10 Design Slots per Workspace',
      '50 AI Tokens',
      '2 GB Cloud Storage',
      'Bulk Printify Listings',
      'Bulk Mockup Generations',
      'Workflow Automation',
    ],
    popular: false,
    priceId: 'price_1PZBvaLCfXuOtw7qKqKOqwkj', // Replace with your Stripe Price ID
  },
  {
    title: 'Basic',
    price: '$9',
    frequency: '/mo',
    features: [
      '50 Design Slots per Workspace',
      '1000 AI Tokens',
      '10 GB Cloud Storage',
      'Bulk Printify Listings',
      'Bulk Mockup Generations',
      'Workflow Automation',
    ],
    popular: true,
    priceId: 'price_1PZBvaLCfXuOtw7qaPqMIRzJ', // Replace with your Stripe Price ID
  },
  {
    title: 'Premium',
    price: '$29',
    frequency: '/mo',
    features: [
      '200 Design Slots per Workspace',
      '5000 AI Tokens',
      '100 GB Cloud Storage',
      'Bulk Printify Listings',
      'Bulk Mockup Generations',
      'Workflow Automation',
    ],
    popular: false,
    priceId: 'price_1PZBvaLCfXuOtw7qEgY3h3kg', // Replace with your Stripe Price ID
  },
  // Add other plans...
];

const Pricing = () => {
  const [subscription, setSubscription] = useState(null);
  const [couponCode, setCouponCode] = useState('');

  
  // Check if `subscription` exists and has a `plan` property
  const subscribed_plan_price_id = subscription && subscription.subscription_plan.price_id ? subscription.subscription_plan.price_id : 'no plan';

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await api.get('/subscription_app/current-subscription/');
        setSubscription(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching subscription:', error);
      }
    };

    fetchSubscription();
  }, []);

  return (
    <section className="text-gray-600 body-font overflow-hidden">
      <div className="container px-5  mx-auto">
        <div className="flex flex-col text-center w-full ">
          <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">
            Fast-Track Your POD Success with our Affordable Plans
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500">
            Harness Easemypod technology to automate your processes and drive significant growth in your Print on Demand business.
          </p>
        </div>


        <div className="flex flex-col justify-center items-center  p-5">
  <h1 className="sm:text-xl text-xl font-medium title-font mb-2 text-gray-900">
    Enter coupon code
  </h1>

  <input
    type="text"
    placeholder="Enter coupon code"
    value={couponCode}
    onChange={(e) => setCouponCode(e.target.value)}
    className="p-2 border rounded"
  />
</div>



        <div className="flex flex-wrap -m-4">
          {pricingData.map((plan, index) => (
            <div key={index} className="p-4 xl:w-1/3 md:w-1/2 w-full">
              <div
                className={`h-full p-6 rounded-lg border-2 ${
                  plan.priceId === subscribed_plan_price_id ? 'border-indigo-400' : 'border-gray-300'
                } flex flex-col justify-between relative overflow-hidden`}
              >
                {plan.popular && (
                  <span className="bg-indigo-700 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                    POPULAR
                  </span>
                )}
                <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                  {plan.title}
                </h2>
                <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                  <span>{plan.price}</span>
                  {plan.frequency && (
                    <span className="text-lg ml-1 font-normal text-gray-500">
                      {plan.frequency}
                    </span>
                  )}
                </h1>
                <div className="flex-1">
                  {plan.features.map((feature, idx) => (
                    <p key={idx} className="flex items-center text-gray-600 mb-2">
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      {feature}
                    </p>
                  ))}
                </div>
                <div className="mt-auto w-full">
                  {
                    plan.priceId === subscribed_plan_price_id ? <p  className="w-full bg-indigo-400 text-white py-2 px-4 rounded-lg hover:bg-indigo-600 transition duration-300 text-center">
                      Current Subscription
                    </p> : <CheckoutButton priceId={plan.priceId} className="w-full" />
                  }
                </div>
                <p className="text-xs text-gray-500 mt-3 text-center">
                </p>
              </div>
            </div>
          ))}
        </div>
        

      </div>
    </section>
  );
};

export default Pricing;
