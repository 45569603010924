import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CURRENT_USER,
  CURRENT_WORKSPACE,
  SERVER_BASE_URL,
} from "../../constants";
import api from "../../api";
import {
  RiCheckboxCircleLine,
  RiDeleteBin2Line,
  RiCheckboxBlankCircleLine,
  RiCheckboxLine,
  RiCheckboxBlankLine,
  RiCheckboxIndeterminateLine,
} from "react-icons/ri";
import {
  PiNumberCircleOne,
  PiNumberCircleTwo,
  PiNumberCircleThree,
} from "react-icons/pi";
import { DotLoader } from "react-spinners";
import StatusModal from "./blocks/StatusModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import the CSS

export default function UpdateMetadata() {
  const [workarea_information, setData] = useState([]);
  const [templates_information, setTemplatesData] = useState([]);
  const navigate = useNavigate();
  const [selectedForUpdate, setselectedForUpdate] = useState([]);
  const [loading, setLoading] = useState(false); // Add state for loading
  const [showModal, setShowModal] = useState(false);
  const [statusSummary, setStatusSummary] = useState({});

  const handleSelectAll = () => {
    if (selectedForUpdate.length === workarea_information.length) {
      // Deselect all
      setselectedForUpdate([]);
    } else {
      // Select all
      const allIds = workarea_information.map((item) => item.lineitem_id);
      setselectedForUpdate(allIds);
    }
  };

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      if (!selectedForUpdate.includes(item.lineitem_id)) {
        setselectedForUpdate([...selectedForUpdate, item.lineitem_id]);
      }
    } else {
      setselectedForUpdate(
        selectedForUpdate.filter((id) => id !== item.lineitem_id)
      );
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    // Show confirmation dialog
    confirmAlert({
      title: "Confirm to generate metadata",
      message:
        "Are you sure you want to generate new metadata? This will overwrite all existing data.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setLoading(true); // Set loading to true when process starts

            const selectedItems = workarea_information.filter((item) =>
              selectedForUpdate.includes(item.lineitem_id)
            );
            const myFormData = new FormData();

            selectedItems.forEach(
              ({
                lineitem_id,
                product_description,
                product_title,
                seo_keywords,
              }) => {
                myFormData.append("lineitem_id", lineitem_id);
                myFormData.append("product_description", product_description);
                myFormData.append("product_title", product_title);
                myFormData.append("seo_keywords", seo_keywords);
              }
            );

            const options = {
              method: "POST",
              body: myFormData,
            };

            try {
              // Submit form data to the backend
              await api.post(
                "/openai_app/generate_textfields_openai/",
                myFormData
              );

              setShowModal(true);
            } catch (error) {
              console.error("Error submitting form data:", error);
            } finally {
              setLoading(false); // Set loading to false when process ends
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            // Do nothing if the user clicks 'No'
          },
        },
      ],
    });
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedInformation = [...workarea_information];
    updatedInformation[index][name] = value;
    setData(updatedInformation);
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const workspace_id = localStorage.getItem(CURRENT_WORKSPACE);

  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedTemplateFiles, setSelectedTemplateFiles] = useState(null);

  const fetchData = async () => {
    try {
      const options = {
        method: "GET",
      };

      const response = await api.get("/workspace/get_workarea_information/", {
        params: { workspace_id: workspace_id },
      });

      setData(response.data);

      // Initialize selectedForUpdate with all IDs when data is fetched
      const allIds = response.data.map((item) => item.lineitem_id);
      setselectedForUpdate(allIds);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const currentWorkspace = localStorage.getItem("CURRENT_WORKSPACE");
    fetchData();
  }, []);

  const saveAllTexts = async (e) => {
    setLoading(true); // Set loading to true when process starts
    e.preventDefault();
    const selectedItems = workarea_information.filter((item) =>
      selectedForUpdate.includes(item.lineitem_id)
    );
    const myFormData = new FormData();

    selectedItems.forEach(
      ({ lineitem_id, product_description, product_title, seo_keywords }) => {
        myFormData.append("lineitem_id", lineitem_id);
        myFormData.append("product_description", product_description);
        myFormData.append("product_title", product_title);
        myFormData.append("seo_keywords", seo_keywords);
      }
    );

    const options = {
      method: "POST",
      body: myFormData,
    };

    try {
      const response = await api.post("/workspace/save_metadata/", myFormData);
      fetchData();
    } catch (error) {
      console.error("Error submitting form data:", error);
    } finally {
      setLoading(false); // Set loading to true when process ends
    }
  };

  const handleClick = () => {
    const url = `/selectMockupTemplates`;
    navigate(url);
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <DotLoader size={100} color="#6D85EC" />
        </div>
      )}

      <StatusModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onHideCallback={fetchData}
      />

      <div class="flex flex-col h-screen">
        <div class="w-full flex items-center justify-center">
          <p class="p-5 text-4xl font-semibold">Update Metadata</p>
        </div>

        <div class="px-8 bg-gray-100 w-full flex flex-row items-start ">
          <div class="flex items-left justify-left w-full ">
            <div className="text-left">
              <ul class="relative flex flex-col md:flex-row gap-2">
                <li class="md:shrink md:basis-0 justify-between flex-1 group flex gap-x-2 md:block">
                  <div class="grow md:grow-0 md:mt-3 pb-5 flex items-center gap-4">
                    <p class="text-sm text-gray-900 dark:text-neutral-900">
                      <PiNumberCircleOne style={{ fontSize: "24px" }} /> Provide
                      input metadata for all designs, this will be used by AI
                      models to better understand the products and generate
                      better quality SEO texts. For best results, describe your
                      product in "Description" text box.
                    </p>
                    {/* <button
                      type="button"
                      class="text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={saveAllTexts}
                    >
                      Save Metadata
                    </button> */}

                    <p class="text-sm text-gray-900 dark:text-neutral-900">
                      <PiNumberCircleTwo style={{ fontSize: "24px" }} /> Once
                      you have updated the metadata, click on Generate Metadata
                      (AI) button to let AI do its magic.
                    </p>
                    <button
                      type="submit"
                      class="text-center py-3 px-4 inline-flex gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={onFormSubmit}
                    >
                      Generate Metadata
                    </button>

                    <p class="text-sm text-gray-900 dark:text-neutral-900">
                      <PiNumberCircleThree style={{ fontSize: "24px" }} />{" "}
                      Customize the SEO texts to your preference and then click
                      the <b>save</b> button to apply the changes.
                    </p>
                    <button
                      type="button"
                      class="text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={saveAllTexts}
                    >
                      Update Metadata
                    </button>

                    <button
                      type="button"
                      class="text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-yellow-400 text-gray-900 hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={() => handleClick()}
                    >
                      Next
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="p-5 w-full flex items-start justify-center">
          <div className="w-full px-5">
            <form onSubmit={onFormSubmit}>
              <table class="min-w-full divide-y-2 bg-white text-sm">
                <thead class="ltr:text-left rtl:text-right bg-gray-900 text-white">
                  <tr>
                    <th class="whitespace-nowrap px-4 py-2 font-medium w-2/12">
                      <div class="flex items-center">
                        <div class="relative inline-block shrink-0 rounded-2xl me-3">
                          <button
                            id="selectAllButton"
                            type="button" // Explicitly set the button type to "button"
                            className="text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gray-600 text-white hover:bg-gray-800 disabled:opacity-50 disabled:pointer-events-none"
                            onClick={handleSelectAll}
                          >
                            {selectedForUpdate.length ===
                            workarea_information.length ? (
                              <RiCheckboxLine style={{ fontSize: "24px" }} />
                            ) : (
                              <RiCheckboxBlankLine
                                style={{ fontSize: "24px" }}
                              />
                            )}
                          </button>
                        </div>

                        <p class="px-4">Design Image</p>
                      </div>
                    </th>
                    <th class="whitespace-nowrap px-4 py-2 font-medium w-5/12">
                      Product Title & Tags
                    </th>
                    <th class="whitespace-nowrap px-4 py-2 font-medium w-5/12">
                      Product Description
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y">
                  {workarea_information.map((item, index) => (
                    <tr class="border-b border-gray-900">
                      <td class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                        <div class="flex items-center">
                          <div class="relative inline-block shrink-0 rounded-2xl me-3">
                            <input
                              type="checkbox"
                              checked={selectedForUpdate.includes(
                                item.lineitem_id
                              )}
                              onChange={(e) => handleCheckboxChange(e, item)}
                              class="form-checkbox h-5 w-5 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
                            />
                          </div>
                          <div class="relative inline-block shrink-0 rounded-2xl me-3">
                            <span>{index + 1}</span>
                          </div>
                          <div class="relative inline-block shrink-0 rounded-2xl me-3">
                            <img
                              src={item.image_thumbnail_path}
                              class="w-full h-[250px] inline-block shrink-0 rounded-2xl"
                              alt=""
                            />
                          </div>
                        </div>
                      </td>
                      <td class="whitespace-nowrap px-4 py-2 text-gray-700">
                        <p class="text-left text-md font-semibold">Title</p>

                        <textarea
                          id="message"
                          rows="3"
                          name="product_title"
                          value={item.product_title}
                          onChange={(e) => handleInputChange(index, e)}
                          class="block resize-none p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Your message..."
                        />

                        <p class="text-left text-md font-semibold">Keywords</p>

                        <textarea
                          id="message"
                          rows="5"
                          name="seo_keywords"
                          value={item.seo_keywords}
                          onChange={(e) => handleInputChange(index, e)}
                          class="block resize-none p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Your message..."
                        />
                      </td>
                      <td class="whitespace-nowrap px-4 py-2 text-gray-700">
                        <p class="text-left text-md font-semibold">
                          Description
                        </p>

                        <textarea
                          id="message"
                          rows="10"
                          name="product_description"
                          value={item.product_description}
                          onChange={(e) => handleInputChange(index, e)}
                          class="block resize-none p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Your message..."
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
