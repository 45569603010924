import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const TermsAndConditions = () => {
  return (
    <div>
      <Navbar />
      <div className="max-w-4xl mx-auto p-5 space-y-4">
        <h1 className="text-3xl font-bold text-center">Terms and Conditions</h1>

        <section>
          <h2 className="text-2xl font-semibold">1. Introduction</h2>
          <p>
            Welcome to easemypod.com! By using our services, you agree to comply
            with and be bound by the following terms and conditions. Please
            review these terms carefully before using our platform.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold">2. Acceptance of Terms</h2>
          <p>
            By accessing and using easemypod.com, you accept and agree to be bound
            by the terms and provision of this agreement. In addition, when
            using these particular services, you shall be subject to any posted
            guidelines or rules applicable to such services.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold">3. Description of Service</h2>
          <p>
            easemypod.com provides a print on demand workflow automation platform.
            Users can automate various processes related to print on demand
            businesses. Services may include, but are not limited to, product
            listings, mockup generation, order processing, and other related
            functionalities.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold">4. User Responsibilities</h2>
          <p>
            Users are responsible for maintaining the confidentiality of their
            account and password and for restricting access to their computer.
            Users agree to accept responsibility for all activities that occur
            under their account or password.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold">
            5. Subscription and Payment
          </h2>
          <p>
          easemypod.com offers subscription-based services. By subscribing,
            users agree to pay the specified subscription fees. All payments are
            non-refundable except as outlined in our Refund Policy.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold">6. Refund Policy</h2>
          <p>
            We offer a 7-day refund policy. If you are not satisfied with our
            services within the first 7 days of your subscription, you may
            request a refund by contacting our support team. Refund requests
            must be submitted within 7 days of the subscription start date.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold">7. Termination of Service</h2>
          <p>
            We reserve the right to terminate or suspend your account and access
            to our services at our sole discretion, without prior notice or
            liability, for any reason whatsoever, including without limitation
            if you breach the Terms.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold">8. Intellectual Property</h2>
          <p>
            The service and its original content, features, and functionality
            are and will remain the exclusive property of easemypod.com and its
            licensors. The service is protected by copyright, trademark, and
            other laws of both the United States and foreign countries.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold">9. Limitation of Liability</h2>
          <p>
            In no event shall easemypod.com, nor its directors, employees,
            partners, agents, suppliers, or affiliates, be liable for any
            indirect, incidental, special, consequential, or punitive damages,
            including without limitation, loss of profits, data, use, goodwill,
            or other intangible losses, resulting from your access to or use of
            or inability to access or use the service.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold">10. Governing Law</h2>
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of the country, without regard to its conflict of law
            provisions.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold">11. Changes to Terms</h2>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material, we will provide
            at least 30 days' notice prior to any new terms taking effect. What
            constitutes a material change will be determined at our sole
            discretion.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold">12. Contact Us</h2>
          <p>
            If you have any questions about these Terms, please contact us at
            support@easemypod.com.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
