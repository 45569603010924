import React, { useState, useEffect } from "react";
import api from "../../../api";
import {
  SHOPIFY_ADMIN_API_TOKEN,
  SHOPIFY_SHOP_NAME,
  CURRENT_WORKSPACE,
} from "../../../constants";

const ShopifyShopModal = ({ closeModal }) => {
  const [shopify_admin_api_token, setShopifyAdminApiToken] = useState("");
  const [shopify_shop_name, setShopifyShopName] = useState("");

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const saveShopifyShopInfo = async () => {
    try {
      // Set the local storage items directly from the state
      localStorage.setItem(SHOPIFY_ADMIN_API_TOKEN, shopify_admin_api_token);
      localStorage.setItem(SHOPIFY_SHOP_NAME, shopify_shop_name);

      // Use the state variables directly in the API call payload
      const response = await api.post("/printify_app/save_shopify_shop_info/", {
        workspace_id: localStorage.getItem(CURRENT_WORKSPACE),
        shopify_admin_api_token: shopify_admin_api_token,
        shopify_shop_name: shopify_shop_name,
      });
      alert("Shopify Shop information saved.");
    } catch (error) {
      console.error("Error saving shopify information:", error);
      alert("Unable to save, validate if the information is correct.");
    }
  };

  const fetchData = async () => {
    try {
      const workspaceId = localStorage.getItem(CURRENT_WORKSPACE);

      const response = await api.get("/printify_app/get_shopify_shop_info/", {
        params: {
          workspace_id: workspaceId,
        },
      });

      // Update state and local storage with the fetched data
      setShopifyAdminApiToken(response.data[0]["shopify_admin_api_token"]);
      setShopifyShopName(response.data[0]["shopify_shop_name"]);

      localStorage.setItem(SHOPIFY_ADMIN_API_TOKEN, response.data[0]["shopify_admin_api_token"]);
      localStorage.setItem(SHOPIFY_SHOP_NAME, response.data[0]["shopify_shop_name"]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOutsideClick}>
      <div className="modal">
        <button className="close-button" onClick={closeModal}>
          &times;
        </button>
        <div className="modal-content">
          <p className="title-font font-medium text-xl text-gray-900">
            Connect to Shopify Store
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            Steps to fetch shopify store information:
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            1. Open your account on Shopify
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            2. Click on "Apps" on left pane and select "App & sales channel settings" from dropdown.
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            3. Click on "Develop Apps"
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            4. Create an App. Give any name (e.g. PODBotApp)
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            5. Navigate to "API Credentials".
          </p>          
          <p className="title-font font-medium text-sm text-gray-900">
            6. Click on "Configure Admin API Scopes".
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            7. Check write_product_listings, read_product_listings, write_products & read_products. Click save.
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            8. Click on "Install App". 
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            9. Under "Admin API access token", click on "Reveal Token Once". 
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            10. Copy the token and paste in textbox below.
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            11. Copy and paste the shop name. You can get it from your URL (your-shop-name.myshopify.com). Just give the shop name without myshopify.com.
          </p>
          <p className="title-font font-medium text-sm text-gray-900">
            12. Click on "Validate & Save" button.
          </p>
          <div className="p-4">
            <p className="title-font font-medium text-sm text-gray-900">
            SHOPIFY ADMIN API TOKEN: 
            </p>
            {/* Input for ADMIN_API_TOKEN  */}
            <input
              type="text"
              id="shopify_admin_api_token"
              rows="3"
              name="shopify_admin_api_token"
              className="block resize-none p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Shopify Admin Api Token"
              value={shopify_admin_api_token}
              onChange={(e) => setShopifyAdminApiToken(e.target.value)}
            />
            <p className="title-font font-medium text-sm text-gray-900">
            SHOPIFY SHOP NAME: 
            </p>
            {/* Input for Shop Name */}
            <input
              type="text"
              id="shopify_shop_name"
              rows="1"
              value={shopify_shop_name}
              name="shopify_shop_name"
              className="block resize-none p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Shopify Shop Name"
              onChange={(e) => setShopifyShopName(e.target.value)}
            />
            {/* Button to select sample listing */}
            <button
              type="button"
              className="text-center py-3 px-4 mt-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
              onClick={saveShopifyShopInfo}
            >
              Validate & Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopifyShopModal;
