export const ACCESS_TOKEN = "access"
export const REFRESH_TOKEN = "refresh"
export const CURRENT_USER = "user"
export const CURRENT_USER_FIRST_NAME = "first_name"
export const CURRENT_USER_LAST_NAME = "last_name"
export const CURRENT_WORKSPACE = "current_workspace"
export const PRINTIFY_API_KEY = "printify_api_key"
export const PRINTIFY_SHOP_ID = "0"
export const SHOPIFY_ADMIN_API_TOKEN = ""
export const SHOPIFY_SHOP_NAME = ""
export const SERVER_BASE_URL = process.env.REACT_APP_API_URL + "/"
