import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import api from "../../../api";
import { isAuthenticated } from './auth';
import { useNavigate } from "react-router-dom";

// Load Stripe with your publishable key from environment variables
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutButton = ({ priceId, couponCode }) => {
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    setAuth(isAuthenticated());
  }, []);

  const handleClick = async () => {
    if (!auth) {
      // Redirect to login page if not authenticated
      navigate('/login');
      return;
    }

    const stripe = await stripePromise;

    try {
      // Send POST request to create a checkout session
      const { data } = await api.post(`${process.env.REACT_APP_API_URL}/subscription_app/create-checkout-session/`, {
        priceId: priceId,
        couponCode: couponCode,  // Include the coupon code in the request
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId: data.id,
      });

      if (error) {
        console.error('Error redirecting to Stripe Checkout:', error);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  return (
    <button onClick={handleClick} className="w-full bg-indigo-700 text-white py-2 px-4 rounded-lg hover:bg-indigo-500 transition duration-300">
      Subscribe
    </button>
  );
};

export default CheckoutButton;
