import React from "react";
import PickMeals from "./Assets/pick-meals-image.png";
import ChooseMeals from "./Assets/choose-image.png";
import DeliveryMeals from "./Assets/delivery-image.png";

const HowItWorks = () => {
  return (
    <section class="text-black body-font">
      <div class="mx-auto flex max-w-[64rem] flex-col items-center space-y-4 text-center">
        <h2 class="font-bold text-3xl leading-[1.1] sm:text-3xl md:text-6xl">
          How it Works
        </h2>

        <p class="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">
          "Experience a complete end-to-end solution for your print-on-demand
          needs with our platform, handling all the operational details so you
          can channel your energy into designing outstanding products."
        </p>
      </div>

      <div class="container px-5 py-8 mx-auto flex flex-wrap">
        <div class="flex flex-wrap w-full">
          <div class="w-1/2 md:pr-10 md:py-6">
            <div class="flex relative pb-12">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full  inline-flex items-center justify-center text-white relative z-10">
                <div class="relative inline-flex items-center justify-center w-[60px] h-[60px] bg-gray-200 dark:bg-gray-700 rounded-full">
                  <svg
                    class="w-[42px] h-[42px] text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 4a2 2 0 0 0-2 2v1h10.968l-1.9-2.28A2 2 0 0 0 10.532 4H5ZM3 19V9h18v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm9-8.5a1 1 0 0 1 1 1V13h1.5a1 1 0 1 1 0 2H13v1.5a1 1 0 1 1-2 0V15H9.5a1 1 0 1 1 0-2H11v-1.5a1 1 0 0 1 1-1Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex-grow pl-4">
                <h2 class="font-bold uppercase title-font text-m text-gray-900 mb-1 tracking-wider">
                  Step 1: Create Your Workspace
                </h2>
                <p class="leading-relaxed">
                  Create a workspace to organize your designs into manageable
                  folders by product type, theme, or batches. This workspace
                  allows you to efficiently categorize and manage your designs,
                  providing flexibility to arrange them based on your project
                  needs.
                </p>
              </div>
            </div>
            <div class="flex relative pb-12">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full  inline-flex items-center justify-center text-white relative z-10">
                <div class="relative inline-flex items-center justify-center w-[60px] h-[60px] bg-gray-200 dark:bg-gray-700 rounded-full">
                  <svg
                    class="w-[42px] h-[42px] text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 3a1 1 0 0 1 .78.375l4 5a1 1 0 1 1-1.56 1.25L13 6.85V14a1 1 0 1 1-2 0V6.85L8.78 9.626a1 1 0 1 1-1.56-1.25l4-5A1 1 0 0 1 12 3ZM9 14v-1H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-4v1a3 3 0 1 1-6 0Zm8 2a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H17Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex-grow pl-4">
                <h2 class="font-bold uppercase title-font text-m text-gray-900 mb-1 tracking-wider">
                  Step 2: Upload Your Designs
                </h2>
                <p class="leading-relaxed">
                  Upload your ready-to-use designs as .png files with a
                  transparent background and the correct sizing required by your
                  templates. These designs should be prepared beforehand to
                  ensure they seamlessly integrate into your chosen product
                  layouts.
                </p>
              </div>
            </div>
            <div class="flex relative pb-12">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full  inline-flex items-center justify-center text-white relative z-10">
                <div class="relative inline-flex items-center justify-center w-[60px] h-[60px] bg-gray-200 dark:bg-gray-700 rounded-full">
                  <svg
                    class="w-[42px] h-[42px] text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3.559 4.544c.355-.35.834-.544 1.33-.544H19.11c.496 0 .975.194 1.33.544.356.35.559.829.559 1.331v9.25c0 .502-.203.981-.559 1.331-.355.35-.834.544-1.33.544H15.5l-2.7 3.6a1 1 0 0 1-1.6 0L8.5 17H4.889c-.496 0-.975-.194-1.33-.544A1.868 1.868 0 0 1 3 15.125v-9.25c0-.502.203-.981.559-1.331ZM7.556 7.5a1 1 0 1 0 0 2h8a1 1 0 0 0 0-2h-8Zm0 3.5a1 1 0 1 0 0 2H12a1 1 0 1 0 0-2H7.556Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex-grow pl-4">
                <h2 class="font-bold uppercase title-font text-m text-gray-900 mb-1 tracking-wider">
                  Step 3: Provide Seed Keywords
                </h2>
                <p class="leading-relaxed">
                  Enter seed keywords that will automatically generate
                  SEO-friendly product titles, descriptions, and tags for your
                  listings. These keywords help optimize your product listings
                  for search engines, ensuring they attract the right audience.
                </p>
              </div>
            </div>
          </div>
          <div class="w-1/2 md:pr-10 md:py-6">
            <div class="flex relative pb-12">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full  inline-flex items-center justify-center text-white relative z-10">
                <div class="relative inline-flex items-center justify-center w-[60px] h-[60px] bg-gray-200 dark:bg-gray-700 rounded-full">
                  <svg
                    class="w-[42px] h-[42px] text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex-grow pl-4">
                <h2 class="font-bold uppercase title-font text-m text-gray-900 mb-1 tracking-wider">
                  Step 4: Review and Update
                </h2>
                <p class="leading-relaxed">
                  Review the automatically generated titles, tags, and
                  descriptions to ensure they align with your branding and
                  product vision. You can refine these details as needed to
                  enhance the appeal and clarity of your product listings.
                </p>
              </div>
            </div>
            <div class="flex relative pb-12">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full  inline-flex items-center justify-center text-white relative z-10">
                <div class="relative inline-flex items-center justify-center w-[60px] h-[60px] bg-gray-200 dark:bg-gray-700 rounded-full">
                  <svg
                    class="w-[42px] h-[42px] text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-7Zm.394 9.553a1 1 0 0 0-1.817.062l-2.5 6A1 1 0 0 0 8 19h8a1 1 0 0 0 .894-1.447l-2-4A1 1 0 0 0 13.2 13.4l-.53.706-1.276-2.553ZM13 9.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex-grow pl-4">
                <h2 class="font-bold uppercase title-font text-m text-gray-900 mb-1 tracking-wider">
                  Step 5: Upload or Select Mockup Templates
                </h2>
                <p class="leading-relaxed">
                  Upload custom mockup templates or select from existing options
                  to visualize your designs for the current batch or workspace.
                  These templates provide a professional presentation of your
                  products, enhancing their visual appeal and customer
                  engagement.
                </p>
              </div>
            </div>
            <div class="flex relative pb-12">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full  inline-flex items-center justify-center text-white relative z-10">
                <div class="relative inline-flex items-center justify-center w-[60px] h-[60px] bg-gray-200 dark:bg-gray-700 rounded-full">
                  <svg
                    class="w-[42px] h-[42px] text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M13 10a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H14a1 1 0 0 1-1-1Z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12c0 .556-.227 1.06-.593 1.422A.999.999 0 0 1 20.5 20H4a2.002 2.002 0 0 1-2-2V6Zm6.892 12 3.833-5.356-3.99-4.322a1 1 0 0 0-1.549.097L4 12.879V6h16v9.95l-3.257-3.619a1 1 0 0 0-1.557.088L11.2 18H8.892Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex-grow pl-4">
                <h2 class="font-bold uppercase title-font text-m text-gray-900 mb-1 tracking-wider">
                  Step 6: Review and Publish Mockups
                </h2>
                <p class="leading-relaxed">
                  Review the mockup images generated from your designs,
                  selecting those that best represent your products. Once
                  satisfied, publish your listings to Printify and your
                  storefront, making them available for purchase by your
                  customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
