import React, { useState, useEffect } from 'react';
import './mobile-notice.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./components/website/Login";
import ForgotPassword from "./components/main/ForgotPassword";
import Register from "./components/website/Register";
import Welcome from "./components/main/Welcome";
// import Workarea from "./components/main/Workarea";
import PrepareDesigns from "./components/main/PrepareDesigns";
import UpdateMetadata from "./components/main/UpdateMetadata";
import SelectMockupTemplates from "./components/main/SelectMockupTemplates";
// import CreateMockups from "./components/main/CreateMockups";
import GenerateMockups from "./components/main/GenerateMockups";
import ReviewMockups from "./components/main/ReviewMockups";
// import CreateListings from "./components/main/CreateListings";
import PublishListings from "./components/main/PublishListings";
import CreatePrintifyListings from "./components/main/CreatePrintifyListings";
import ProtectedRoute from "./components/ProtectedRoute";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from "./components/website/Home";
import Account from "./components/website/Account";
import Success from "./components/website/Success";
import Failure from "./components/website/Failure";
// import Resources from './components/website/Resources';
// import TutorialDetail from './components/website/TutorialDetail';
import AboutUs from './components/website/AboutUs';
import Returns from './components/website/Returns';
import TermsAndConditions from './components/website/TermsAndConditions';
import PrivacyPolicy from './components/website/PrivacyPolicy';
import TutorialList from './components/website/TutorialList';
import Tutorial from './components/website/Tutorial';
import ContactUs from './components/website/ContactUs';

import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';


// const tagManagerArgs = {
//   gtmId: 'GTM-WKS3VS5M'
// }
// TagManager.initialize(tagManagerArgs)




function Logout() {
  localStorage.clear()
  return <Navigate to="/" />
}

function RegisterAndLogout() {
  localStorage.clear()
  return <Register />
}



function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', checkScreenSize);
    checkScreenSize();

    window.dataLayer.push({
      event: 'pageview'
    });

    ReactGA.event({
      category: 'User',
      action: 'Created an Account'
    });
    
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <div>
      {isMobile ? (
        <div className="mobile-notice">
          Notice: Our website is currently optimized for use on larger screens, such as desktops and laptops. For the best experience, please access our site from a computer or a device with a larger display. Thank you for your understanding.
        </div>
      ) : (
        <div className="main-content">
          <BrowserRouter>
            {/* <Layout> */}
            <div>
            <ToastContainer />
              <Routes>
                <Route
                  path="/"
                  element={
                        <Home />
                  }
                />
                <Route path="/home" element={<Home />} />
                <Route path="/account" element={<Account />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/register" element={<RegisterAndLogout />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/success" element={<Success />} />
                <Route path="/failure" element={<Failure />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/returns" element={<Returns />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                {/* <Route path="/resources" element={<Resources />} /> */}
                {/* <Route path="/tutorial/:id" element={<TutorialDetail />} /> */}

                <Route path="/resources" element={<TutorialList /> } />
                <Route path="/tutorial/:file" element={<Tutorial /> } />
                <Route path="/contactus" element={<ContactUs /> } />

              <Route path="/welcome" element={<ProtectedRoute><Layout><Welcome /></Layout></ProtectedRoute>} />
              {/* <Route path="/workarea" element={<ProtectedRoute><Layout><Workarea /></Layout></ProtectedRoute>} /> */}
              <Route path="/prepareDesigns" element={<ProtectedRoute><Layout><PrepareDesigns /></Layout></ProtectedRoute>} />
              <Route path="/updateMetadata" element={<ProtectedRoute><Layout><UpdateMetadata /></Layout></ProtectedRoute>} />
              <Route path="/generateMockups" element={<ProtectedRoute><Layout><GenerateMockups /></Layout></ProtectedRoute>} />
              <Route path="/reviewMockups" element={<ProtectedRoute><Layout><ReviewMockups /></Layout></ProtectedRoute>} />
              {/* <Route path="/createMockups" element={<ProtectedRoute><Layout><CreateMockups /></Layout></ProtectedRoute>} /> */}
              <Route path="/selectMockupTemplates" element={<ProtectedRoute><Layout><SelectMockupTemplates /></Layout></ProtectedRoute>} />
              {/* <Route path="/createListings" element={<ProtectedRoute><Layout><CreateListings /></Layout></ProtectedRoute>} /> */}
              <Route path="/publishListings" element={<ProtectedRoute><Layout><PublishListings /></Layout></ProtectedRoute>} />
              <Route path="/createPrintifyListings" element={<ProtectedRoute><Layout><CreatePrintifyListings /></Layout></ProtectedRoute>} />
              </Routes>
            </div>
            {/* </Layout> */}
          </BrowserRouter>
        </div>
      )}
    </div>
  );
}

export default App;
