import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CURRENT_USER,
  CURRENT_WORKSPACE,
  SERVER_BASE_URL,
} from "../../constants";
import api from "../../api";
import WorkflowNavigation from "./blocks/WorkflowNavigation";
// import WorkspaceModal from './blocks/WorkspaceModal'; // Adjust the import path as necessary
import {
  RiCheckboxCircleLine,
  RiDeleteBin2Line,
  RiCheckboxBlankCircleLine,
} from "react-icons/ri";
import DeleteModal from "./blocks/DeleteModal"; // Import the modal component
import { RiProgress8Line } from "react-icons/ri";
import {
  ClipLoader,
  BarLoader,
  BeatLoader,
  BounceLoader,
  CircleLoader,
  DotLoader,
  RingLoader,
} from "react-spinners";

export default function GenerateMockups() {
  const [workarea_information, setData] = useState([]);
  const [mockup_job_logs, setJobLogs] = useState([]);
  const [templates_information, setTemplatesData] = useState([]);
  const [loading, setLoading] = useState(false); // Add state for loading
  const [selectedForDelete, setSelectedForDelete] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const pollingCounter = useRef(0);
  const navigate = useNavigate();
  // const [showModal, setShowModal] = useState(false);

  const generateMockups = async () => {
    try {
      alert(
        "Generating mockup images takes time. Once ready, the images will be available in 'Review Mockups' Tab."
      );
      setLoading(true); // Set loading to true when upload starts
      const response = await api.get("/photopea_app/generate_mockups/", {
        params: { workspace_id: localStorage.getItem(CURRENT_WORKSPACE) },
      });

      fetchData();
    } catch (error) {
      console.error("Error generating mockups:", error);
    } finally {
      setLoading(false); // Set loading to false when upload completes
    }
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const workspace_id = localStorage.getItem(CURRENT_WORKSPACE);

  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedTemplateFiles, setSelectedTemplateFiles] = useState(null);

  const fetchData = async () => {
    try {
      const options = {
        method: "GET",
      };

      const response = await api.get("/workspace/get_workarea_information/", {
        params: { workspace_id: workspace_id },
      });

      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchJobLogs = async () => {
    try {
      const options = {
        method: "GET",
      };
      const response = await api.get("/mockups_app/get_mockup_job_logs/", {
        params: { workspace_id: workspace_id },
      });

      setJobLogs(response.data.job_logs);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {}, [mockup_job_logs]);

  // Fetch job logs on component mount or workspace_id change, and poll for 10 times
  useEffect(() => {
    fetchJobLogs(); // Fetch immediately when component mounts or workspace_id changes

    const interval = setInterval(() => {
      pollingCounter.current += 1;
      if (pollingCounter.current >= 10) {
        clearInterval(interval);
      } else {
        fetchJobLogs();
      }
    }, 10000); // Fetch every 10 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [workspace_id]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleClick = () => {
    const url = `/reviewMockups`;
    navigate(url);
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <DotLoader size={100} color="#6D85EC" />
        </div>
      )}

      <div class="flex flex-col h-screen">
        <div class=" w-full  flex items-center justify-center">
          <p class="p-5 text-4xl font-semibold">Generate Mockups</p>
        </div>

        <div className="px-8 bg-gray-100 w-full flex flex-row items-start">
          <div className="flex items-left justify-left w-full">
            <div className="text-left">
              <ul className="relative flex flex-col md:flex-row gap-2">
                <li className="md:shrink md:basis-0 justify-between flex-1 group flex gap-x-2 md:block">
                  <div className="grow md:grow-0 md:mt-3 pb-5 flex items-center gap-4">
                    <p class="text-sm text-gray-900 dark:text-neutral-900 w-2/5">
                      Click on "Generate Mockups button to create product
                      mockups with selected templates. Note that this process
                      takes times and can run in the background. You can monitor
                      the progress below."
                    </p>

                    <button
                      type="button"
                      class=" text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent  bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={() => generateMockups()}
                    >
                      Generate Mockups
                    </button>

                    <button
                      type="button"
                      class=" text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-yellow-400 text-gray-900 hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={() => handleClick()}
                    >
                      Next
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class=" container px-5 mx-auto flex flex-wrap">
          <p class="py-2 text-xl font-semibold">Progress Information</p>
        </div>
        <div className="container px-5 mx-auto flex flex-wrap">
          <button
            type="button"
            class="py-2  text-center  px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent  bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={() => fetchJobLogs()}
          >
            Refresh Logs
          </button>
        </div>
        <div className="py-2 container px-5 mx-auto flex flex-wrap">
          <div className="flex flex-wrap w-full">
            {mockup_job_logs.length === 0 ? (
              <p>No active jobs</p>
            ) : (
              mockup_job_logs.map((log) => (
                <div className="flex relative  w-full" key={log.id}>
                  <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider flex items-center">
                    {log.status === "Completed" && (
                      <RiProgress8Line
                        style={{ color: "#86D25B", fontSize: "24px" }}
                      />
                    )}
                    {log.status === "In Progress" && (
                      <RiProgress8Line
                        style={{ color: "#F0BB56", fontSize: "24px" }}
                      />
                    )}
                    {log.status === "Failed" && (
                      <RiProgress8Line
                        style={{ color: "#D25F5B", fontSize: "24px" }}
                      />
                    )}
                    {log.status === "Pending" && (
                      <RiProgress8Line
                        style={{ color: "#E1E0E0", fontSize: "24px" }}
                      />
                    )}
                    <span className="ml-2">{log.template_file_name}</span>
                  </h2>
                </div>
              ))
            )}
          </div>
        </div>

      </div>
    </div>
  );
}
