import React, { useState } from "react";
import api from "../../../api";
import { ACCESS_TOKEN, REFRESH_TOKEN, CURRENT_USER } from "../../../constants";

const Profile = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChangePassword = async () => {
    console.log("now here");
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setError("Please fill in all fields");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError("New passwords do not match");
      return;
    }

    try {
      console.log("Aaya aaya idhar aaya");
      const username = localStorage.getItem(CURRENT_USER);
      const response = await api.post("/api/reset_password", {
        username,
        currentPassword,
        newPassword,
      });
      console.log(response);
      setSuccessMessage(response.data.message);
      setError("");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  return (
    <section className="text-gray-600 body-font overflow-hidden">


      <section class=" py-5 text-gray-600 body-font">
        <div class="container mx-auto">
          <div class="text-left">
            <h1 class="sm:text-3xl text-2xl font-medium text-left title-font text-gray-900 ">
              Change your password
            </h1>
          </div>
          <div class="flex flex-wrap ">
            <div class="w-1/4">
              <div class="bg-gray-100 rounded flex p-4 h-full items-center">
                <span class="title-font font-medium">Current Password</span>
              </div>
            </div>
            <div class="w-1/4">
              <div class="bg-gray-100 rounded flex p-4 h-full items-center">
                
                <input type="password" placeholder="Current Password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} class="w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
              </div>
            </div>
            <div class="p-2 w-2/4"></div>
            <div class="w-1/4">
              <div class="bg-gray-100 rounded flex p-4 h-full items-center">
                <span class="title-font font-medium">New Password</span>
              </div>
            </div>
            <div class="w-1/4">
              <div class="bg-gray-100 rounded flex p-4 h-full items-center">
                
                <input type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)} class="w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />

              </div>
            </div>
            <div class="p-2 w-2/4"></div>
            <div class="w-1/4">
              <div class="bg-gray-100 rounded flex p-4 h-full items-center">
                <span class="title-font font-medium">New Password</span>
              </div>
            </div>
            <div class="w-1/4">
              <div class="bg-gray-100 rounded flex p-4 h-full items-center">
                
                <input type="password"
                  placeholder="Confirm New Password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)} class="w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
              </div>
            </div>
            <div class="p-2 w-2/4"></div>
          </div>
          <button
            onClick={handleChangePassword}
            class=" px-2  text-white bg-indigo-700 border-0 py-2  focus:outline-none hover:bg-indigo-500 rounded text-lg"
          >
            Change Password
          </button>

          {error && <p className="text-red-500 mt-2">{error}</p>}
          {successMessage && (
            <p className="text-green-500 mt-2">{successMessage}</p>
          )}
        </div>
      </section>
    </section>
  );
};

export default Profile;
