import React from "react";
import Navbar from "./Navbar";
import { VscPass } from "react-icons/vsc";
import { PiMaskHappyThin, PiMaskSadThin } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

const Failure = () => {
  const navigate = useNavigate();

  const goHome = async (e) => {
    navigate("/");
  };

  const goToAccount = async (e) => {
    navigate("/account");
  };

  return (
    <div>
      <Navbar />

      <section class="text-gray-600 body-font">
        <div class="container mx-auto flex px-5 py-48 md:flex-row flex-col items-center">
          <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <PiMaskSadThin size={128} color="#ff7591" />
          </div>
          <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              Subscription failed
            </h1>
            <p class="mb-8 leading-relaxed">Please try again</p>
            <div class="flex justify-center">
              <button
                onClick={goHome}
                class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg mr-4"
              >
                Home
              </button>
              <button
                onClick={goToAccount}
                class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              >
                Your Account
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Failure;
