import React, { useState } from "react";
import "./custom.css"; // Import the CSS file
import Navbar from "./Navbar";
import Pricing from "./blocks/Pricing";
import Profile from "./blocks/Profile";
import { ACCESS_TOKEN, REFRESH_TOKEN, CURRENT_USER, CURRENT_USER_FIRST_NAME, CURRENT_USER_LAST_NAME } from "../../constants";

const Account = () => {
  const [activeContent, setActiveContent] = useState("Subscription");
  const current_user_first_name = localStorage.getItem(CURRENT_USER_FIRST_NAME);
  const current_user_last_name = localStorage.getItem(CURRENT_USER_LAST_NAME);


  const renderContent = () => {
    switch (activeContent) {
      case "Subscription":
        return <Subscription />;
      case "Profile":
        return <YourProfile />;
      case "Settings":
        return <Settings />;
      // case "Your Data":
      //   return <YourData />;
      case "Logout":
        return <Logout />;
      default:
        return <Subscription />;
    }
  };

  return (
    <div className="home-container">
      <Navbar />

      <div className="flex h-screen ">
        <div className="w-2/12 bg-gray-900 text-white p-4">
          <h2 className="text-xl font-bold mb-4">Sidebar</h2>
          <ul>
            <li className="mb-2">
              <button
                onClick={() => setActiveContent("Profile")}
                className="hover:text-gray-300"
              >
                Profile
              </button>
            </li>
            <li className="mb-2">
              <button
                onClick={() => setActiveContent("Subscription")}
                className="hover:text-gray-300"
              >
                Subscription
              </button>
            </li>

            <li className="mb-2">
              <button
                onClick={() => setActiveContent("Settings")}
                className="hover:text-gray-300"
              >
                Settings
              </button>
            </li>
            {/* <li className="mb-2">
              <button
                onClick={() => setActiveContent("Your Data")}
                className="hover:text-gray-300"
              >
                Your Data
              </button>
            </li> */}
            <li className="mb-2">
              <button
                onClick={() => setActiveContent("Logout")}
                className="hover:text-gray-300"
              >
                Logout
              </button>
            </li>
          </ul>
        </div>

        <div className="flex-1 p-6">
        <h2 className="text-xl font-bold mb-4">Hi {current_user_first_name}!</h2>
          {renderContent()}
          </div>
      </div>
    </div>
  );
};

const Subscription = () => (
  <div>
    <Pricing />
  </div>
);

const YourProfile = () => (
  <div>
    <Profile />
  </div>
);

// const YourData = () => (
//   <div>
//     <h1 className="text-3xl font-bold mb-4">Your Data</h1>
//     <p>This section is currently under development. When complete, you will be able to download all your data sets from here. </p>
//   </div>
// );

const Settings = () => (
  <div>
    <h1 className="text-3xl font-bold mb-4">Settings</h1>
    <p>Adjust your settings here.</p>
  </div>
);

const Logout = () => (
  <div>
    <h1 className="text-3xl font-bold mb-4">Logout</h1>
    <p>You have been logged out.</p>
  </div>
);

export default Account;
