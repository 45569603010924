import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { LiaUserFriendsSolid } from "react-icons/lia";

const AboutUs = () => {
  return (
    <div>
      <Navbar />

      <div class="mx-auto flex max-w-[64rem] flex-col items-left p-5 space-y-4 text-left">
        <h2 class="font-bold text-3xl leading-[1.1] sm:text-3xl md:text-6xl ">
          <span>About Us</span>
        </h2>

        <h2 class="font-bold text-3xl leading-[1.1] sm:text-3xl md:text-6xl ">
          <div className="flex items-left justify-left">
            <LiaUserFriendsSolid />
          </div>
        </h2>

        <p class="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">
          At Easemypod.com, we are a small team with big dreams and immense
          caliber, passionate about leveraging technology to solve everyday
          challenges. Our journey began with our own experiences in the print on
          demand business, where we realized how much manual effort was
          involved. Driven by our love for innovation, we set out to automate
          the entire process, leading to the creation of Easemypod.com.
        </p>
        <p>
          {" "}
          <br />
        </p>
        <p class="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">
          Our mission is to harness the power of advanced technologies,
          including AI, to streamline and enhance your workflow. We believe that
          by eliminating repetitive tasks, we can empower you to focus on what
          truly matters: growing your business and achieving your goals.
        </p>
        <p>
          {" "}
          <br />
        </p>
        <p class="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">
          We are dedicated to providing a platform that not only simplifies your
          operations but also propels your success. We sincerely hope that POD
          Express becomes an invaluable tool in your journey, helping you to
          sell faster, scale smarter, and thrive in the competitive world of
          print on demand and digital products.
        </p>
        <p>
          {" "}
          <br />
        </p>
        <p class="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">
          Try our platform and do share your feedback.
        </p>
        <p>
          {" "}
          <br />
        </p>
        <p class="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">
          Thanks
        </p>
        <p class="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">
          easemypod.com Team
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
