import React, { useState, useEffect } from "react";
import api from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import WorkflowNavigation from "./blocks/WorkflowNavigation";
import DeleteModal from "./blocks/DeleteModal";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CURRENT_USER,
  CURRENT_WORKSPACE,
  PRINTIFY_API_KEY,
  PRINTIFY_SHOP_ID,
  SERVER_BASE_URL,
} from "../../constants";
import PrintifyShopModal from "./blocks/PrintifyShopModal"; // Import your modal component
import {
  ClipLoader,
  BarLoader,
  BeatLoader,
  BounceLoader,
  CircleLoader,
  DotLoader,
  RingLoader,
} from "react-spinners";

export default function CreatePrintifyListings() {
  const [workarea_information, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Add state for loading
  const navigate = useNavigate();

  const location = useLocation();
  const workspace_id = localStorage.getItem(CURRENT_WORKSPACE);

  // Function to handle opening modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.get("/workspace/get_workarea_information/", {
        params: { workspace_id: workspace_id },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const createPrintifyListings = async () => {
    try {
      setLoading(true); // Set loading to true when upload starts

      const response = await api.post(
        "/printify_app/create_printify_listings_v2/",
        {
          workspace_id: localStorage.getItem(CURRENT_WORKSPACE),
        }
      );
      alert("Listings published on Printify");
      fetchData();
    } catch (error) {
      console.error("Error selecting sample listing:", error);
    } finally {
      setLoading(false); // Set loading to false when upload completes
    }
  };

  const handleClick = () => {
    const url = `/publishListings`;
    navigate(url);
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <DotLoader size={100} color="#6D85EC" />
        </div>
      )}
      <div class="flex flex-col h-screen">
        <div class="w-full flex items-center justify-center">
          <p class="p-5 text-4xl font-semibold">Create Printify Listings</p>
        </div>

        <div class="px-8 bg-gray-100 w-full flex flex-row items-start">
          <div class="flex items-left justify-left w-full">
            <div class="text-left w-full">
              <ul class="relative flex flex-col md:flex-row gap-2 w-full">
                <li class="md:shrink md:basis-0 justify-between flex-1 group flex gap-x-2 md:block w-full">
                  <div class="grow md:grow-0 md:mt-3 pb-5 flex items-center gap-4 w-full">
                    <div class="text-sm text-gray-900 dark:text-neutral-900 w-full">
                      <p>
                        Now that your designs and mockups are ready, you can
                        publish them to your stores. It involves a few steps.
                        <ul class="list-disc pl-5">
                          <li>1. Connect your Printify Store.</li>
                          <li>2. Create listings in your Printify account.</li>
                          <li>
                            3. Go to your Printify account and ensure all
                            listings are created. You can also check the mockups
                            that Printify adds by default. The mockups generated
                            on this platform will be uploaded in later steps.
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div class="w-full flex flex-col md:flex-row gap-2">
                      <button
                        type="button"
                        class="text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none w-full md:w-auto"
                        onClick={openModal}
                      >
                        1. Connect Printify Store
                      </button>

                      <button
                        type="button"
                        class="text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none w-full md:w-auto"
                        onClick={createPrintifyListings}
                      >
                        2. Create Printify Listings
                      </button>

                      <button
                        type="button"
                        class="text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-yellow-400 text-gray-900 hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none w-full md:w-auto"
                        onClick={() => handleClick()}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-5 gap-4 p-4 text-center items-center justify-center ">
          {workarea_information.length > 0 ? (
            workarea_information.map((item, index) => (
              <label key={index} class="cursor-pointer">
                <div
                  key={item.lineitem_id}
                  class="shadow-lg flex flex-col items-center justify-center bg-gray-100"
                >
                  <div class="w-8/12 h-auto relative overflow-hidden rounded-lg max-w-xs group ">
                    <img
                      class="mx-auto w-full h-auto"
                      src={item.image_thumbnail_path}
                      alt=""
                    />
                  </div>

                  <span class="text-sm text-gray-900 dark:text-neutral-900">
                    Printify Product ID: {item.printify_product_id}
                  </span>
                </div>
              </label>
            ))
          ) : (
            <p>No designs available</p>
          )}
        </div>

        {/* Render modal component conditionally */}
        {isModalOpen && (
          <PrintifyShopModal closeModal={closeModal}>
            {/* Content of the modal goes here */}
            <p>This is the modal content.</p>
          </PrintifyShopModal>
        )}
      </div>
    </div>
  );
}
