import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');  // Set the root element for accessibility

const DeleteModal = ({ isOpen, onRequestClose, onDelete }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Delete Confirmation"
      className="bg-white p-5 rounded-lg shadow-lg max-w-md mx-auto mt-10"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
    >
      <h2 className="text-xl font-semibold mb-4">Confirm Delete</h2>
      <p className="mb-4">Are you sure you want to delete the selected items?</p>
      <div className="flex justify-end gap-2">
        <button
          className="py-2 px-4 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          onClick={onRequestClose}
        >
          Cancel
        </button>
        <button
          className="py-2 px-4 bg-red-600 text-white rounded hover:bg-red-700"
          onClick={onDelete}
        >
          Proceed
        </button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
