import React from 'react';
import Header from './common/Header';
import Footer from './common/Footer';

function Layout({ children }) {
  return (
    <div>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
}
  
export default Layout;

