import React, { useState, useEffect } from 'react';
import { CURRENT_WORKSPACE } from '../../../constants';
import api from "../../../api";

const StatusModal = ({ show, onHide, onHideCallback }) => {
  const [statusSummary, setStatusSummary] = useState({});
  const [loading, setLoading] = useState(false);  // State to manage loading status

  const fetchData = async () => {
    setLoading(true);  // Set loading state to true
    try {
      const response = await api.get("/openai_app/task-status-summary/", {
        params: { workspace_id: localStorage.getItem(CURRENT_WORKSPACE) },
      });
      // Check if response data has the correct structure
      if (response.data && response.data.status_summary && typeof response.data.status_summary === 'object') {
        setStatusSummary(response.data.status_summary);
      } else {
        console.error("Invalid data structure received:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);  // Reset loading state
    }
  };

  useEffect(() => {
    if (show) {
      fetchData();  // Fetch data when the modal is shown
    }
  }, [show]);

  const handleRefresh = async () => {
    await fetchData();  // Refresh the data
  };

  const handleClose = () => {
    if (onHideCallback) {
      onHideCallback();  // Call the callback function if provided
    }
    onHide();  // Hide the modal
  };

  if (!show) return null;

  return (
    <div
      className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50 flex items-center justify-center"
      onClick={handleClose} // Close modal when clicking outside
    >
      <div
        className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg"
        onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to the overlay
      >
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-xl font-semibold">Task Status Summary</h2>
          <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
            &times;
          </button>
        </div>
        <div className="mt-4">
          {Object.keys(statusSummary).length > 0 ? (
            <ul className="space-y-2">
              {Object.entries(statusSummary).map(([status, count]) => (
                <li key={status} className="flex justify-between items-center">
                  <span className="font-medium">{status}:</span>
                  <span>{count}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p>No data available</p>
          )}
        </div>
        <div className="mt-6 flex justify-between">
          <button
            onClick={handleRefresh}
            className={`bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading}
          >
            {loading ? 'Refreshing...' : 'Refresh Logs'}
          </button>
          <button
            onClick={handleClose}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default StatusModal;
