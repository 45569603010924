import React from "react";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import LogoWhite from "./Assets/LogoWhite";

const Footer = () => {
  return (
    <div className="mt-8 bg-gray-900 pt-9">
      <div className="mx-auto w-full  px-4 xl:px-0">
        <div className="flex flex-col justify-between sm:px-[18px] md:flex-row md:px-10">
          <div className="w-2/4 px-5">
            <LogoWhite />
            <p className="mt-[18px] text-[15px] font-normal text-white/[80%] ">
              easemypod.com simplifies and accelerates your Print on Demand and
              digital product sales. Our app offers end-to-end workflow
              automation, robust design tools, and seamless integration with
              leading platforms. With features like bulk listing generation, AI
              tokens, and ample storage, easemypod.com ensures you can sell
              faster, scale efficiently, and maximize your profits.
            </p>
          </div>

          <div className="mt-6 flex flex-col justify-between text-white w-1/4">
            <div className="">
              <p className="text-deutziawhite font-inter text-[18px] font-medium leading-normal">
                Useful Links
              </p>
              <ul>
                <li className="mt-[5px]">
                  <a
                    className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                    href="/"
                  >
                    Home
                  </a>
                </li>
                <li className="mt-[5px]">
                  <a
                    className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                    href="/aboutus"
                  >
                    About Us
                  </a>
                </li>
                <li className="mt-[5px]">
                  <a
                    className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                    href="/contactus"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-6 flex  flex-col justify-between text-white w-1/4">
            <div className="">
              <p className="text-deutziawhite font-inter text-[18px] font-medium leading-normal">
                Our Policies
              </p>
              <ul>
                <li className="mt-[5px]">
                  <a
                    className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                    href="/returns"
                  >
                    Our Returns Policy
                  </a>
                </li>
                <li className="mt-[5px]">
                  <a
                    className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                    href="/terms-and-conditions"
                  >
                    Terms and conditions
                  </a>
                </li>
                <li className="mt-[5px]">
                  <a
                    className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                    href="/privacy-policy"
                  >
                    Privcay policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="mt-[30px] text-white" />
        <div className="flex items-center justify-center pb-4 md:py-4">
          <p className="text-[10px] font-normal text-white md:text-[12px]">
            © Copyright 2024, All Rights Reserved by www.easemypod.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
