import {
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    CURRENT_USER,
    CURRENT_WORKSPACE,
    SERVER_BASE_URL,
  } from "./../../../constants";


export const isAuthenticated = () => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    console.log(localStorage.getItem(ACCESS_TOKEN))
    if (!token) return false;
  
    // Optionally, you can add token validation logic here (e.g., checking expiration)
    return true;
  };
  