import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Returns = () => {
  return (
    <div>
      <Navbar />
      <div className="max-w-4xl mx-auto p-5">
        <h2 className="text-3xl font-bold mb-4">Refund Policy</h2>
        <p className="mb-4">
          At easemypod.com, we strive to ensure that our customers are satisfied
          with our print on demand workflow automation platform. If for any
          reason you are not completely satisfied with your purchase, we offer a
          7-day refund policy under the following conditions:
        </p>
        <h3 className="text-2xl font-semibold mb-2">1. Eligibility</h3>
        <p className="mb-4">
          To be eligible for a refund, you must request the refund within 7 days
          of your initial purchase. The refund request must be submitted via our
          official support channels.
        </p>
        <h3 className="text-2xl font-semibold mb-2">2. Refund Process</h3>
        <p className="mb-4">
          To initiate a refund, please contact our support team at{" "}
          <a href="mailto:support@easemypod.com" className="text-indigo-500">
            support@easemypod.com
          </a>{" "}
          with your order details and the reason for your refund request. Our
          team will review your request and notify you of the approval or
          rejection of your refund within 5 business days.
        </p>
        <h3 className="text-2xl font-semibold mb-2">3. Refund Approval</h3>
        <p className="mb-4">
          If your refund request is approved, we will process the refund within
          7 business days. The refund will be credited to your original method
          of payment. Please note that the time it takes for the refund to
          appear in your account may vary depending on your bank or credit card
          provider.
        </p>
        <h3 className="text-2xl font-semibold mb-2">4. Non-Refundable Items</h3>
        <p className="mb-4">
          Certain items are non-refundable, including:
          <ul className="list-disc list-inside ml-4">
            <li>Any services that have been fully delivered or utilized.</li>
            <li>One-time setup fees, if applicable.</li>
            <li>Any promotional or discounted offers.</li>
          </ul>
        </p>
        <h3 className="text-2xl font-semibold mb-2">
          5. Changes to the Refund Policy
        </h3>
        <p className="mb-4">
          easemypod.com reserves the right to modify this refund policy at any
          time. Changes to the refund policy will be posted on this page. We
          encourage you to review this policy periodically to stay informed
          about our refund practices.
        </p>
        <h3 className="text-2xl font-semibold mb-2">6. Contact Us</h3>
        <p>
          If you have any questions or concerns about our refund policy, please
          contact us at{" "}
          <a href="mailto:support@easemypod.com" className="text-indigo-500">
            support@easemypod.com
          </a>
          . Our customer support team is here to help you.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Returns;
