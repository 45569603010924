import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CURRENT_USER,
  CURRENT_WORKSPACE,
  SERVER_BASE_URL,
} from "../../constants";
import api from "../../api";
import WorkflowNavigation from "./blocks/WorkflowNavigation";
// import WorkspaceModal from './blocks/WorkspaceModal'; // Adjust the import path as necessary
import {
  RiCheckboxCircleLine,
  RiDeleteBin2Line,
  RiCheckboxBlankCircleLine,
} from "react-icons/ri";
import DeleteModal from "./blocks/DeleteModal"; // Import the modal component
import { saveAs } from "file-saver";
import {
  ClipLoader,
  BarLoader,
  BeatLoader,
  BounceLoader,
  CircleLoader,
  DotLoader,
  RingLoader,
} from "react-spinners";

export default function ReviewMockups() {
  const [arr_mockup_images_grid, setData] = useState([]);
  const [selectedMockups, setSelectedMockups] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await api.get("/mockups_app/get_mockup_images_grid/", {
        params: { workspace_id: localStorage.getItem(CURRENT_WORKSPACE) },
      });

      setData(response.data.arr_mockup_images_grid);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const currentWorkspace = localStorage.getItem("CURRENT_WORKSPACE");
    fetchData();
  }, []);

  const handleClick = () => {
    const url = `/createPrintifyListings`;
    navigate(url);
  };

  const toggleImageSelection = (mockupId) => {
    setSelectedMockups((prevSelectedImages) =>
      prevSelectedImages.includes(mockupId)
        ? prevSelectedImages.filter((id) => id !== mockupId)
        : [...prevSelectedImages, mockupId]
    );
  };

  const deleteSelectedMockups = async () => {
    alert("Deleting selected mockups");
    try {
      await api.post("/mockups_app/delete_selected_mockups/", {
        mockup_ids: selectedMockups,
      });
      fetchData(); // Refresh data after deletion
    } catch (error) {
      console.error("Error deleting mockups:", error);
    }
  };

  const downloadFiles = async () => {
    setLoading(true); // Set loading to true when upload starts
    try {
      const response = await api.get(
        `/mockups_app/download_mockup_files/?workspace_id=${localStorage.getItem(CURRENT_WORKSPACE)}`,
        { responseType: "blob" } // Important for binary data
      );
      const blob = new Blob([response.data], { type: "application/zip" });
      saveAs(blob, "easemypod_mockups.zip");
    } catch (error) {
      console.error("Error downloading files:", error);
    } finally {
      setLoading(false); // Set loading to false when upload completes
    }
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <DotLoader size={100} color="#6D85EC" />
        </div>
      )}

      <div class="flex flex-col h-screen">
        <div class=" w-full  flex items-center justify-center">
          <p class="p-5 text-4xl font-semibold">Review Mockups</p>
        </div>

        <div className="px-8 bg-gray-100 w-full flex flex-row items-start">
          <div className="flex items-left justify-left w-full">
            <div className="text-left">
              <ul className="relative flex flex-col md:flex-row gap-2">
                <li className="md:shrink md:basis-0 justify-between flex-1 group flex gap-x-2 md:block">
                  <div className="grow md:grow-0 md:mt-3 pb-5 flex items-center gap-4">
                    <p className="text-sm text-gray-900 dark:text-neutral-900">
                      Upload your designs for this workspace. Ensure that the
                      files are sized according to the templates and have
                      transparent background.
                    </p>

                    <button
                      type="button"
                      class=" text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent  bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={deleteSelectedMockups}
                      disabled={selectedMockups.length === 0}
                    >
                      Delete Selected Mockups
                    </button>
                    <button
                      type="button"
                      className="text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700"
                      onClick={downloadFiles}
                    >
                      Download Your Files
                    </button>

                    <button
                      type="button"
                      class=" text-center py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-yellow-400 text-gray-900 hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={() => handleClick()}
                    >
                      Next
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="p-5 w-full flex items-start justify-center">
          <div className="w-full px-5">
            <form onSubmit={deleteSelectedMockups}>
              <table className="min-w-full divide-y-2 bg-white text-sm">
                <thead className="ltr:text-left rtl:text-right bg-gray-900 text-white">
                  <tr>
                    <th className="whitespace-nowrap px-4 py-2 font-medium w-2/12">
                      Design Image
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-medium w-5/12">
                      Mockup Images
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {arr_mockup_images_grid.map((item, index) => (
                    <tr key={index} className="border-b border-gray-900">
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                        <div className="flex items-center">
                          <div className="relative inline-block shrink-0 rounded-2xl me-3">
                            <img
                              src={item.design_image_path}
                              className="w-full h-[250px] inline-block shrink-0 rounded-2xl"
                              alt=""
                            />
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <div className="grid grid-cols-4 gap-4 p-4 text-center bg-white">
                          {item.mockup_images.map((mockup, idx) => (
                            <label key={index} class="cursor-pointer">
                              <div
                                key={idx}
                                class=" flex flex-col items-center justify-center "
                              >
                                <div class="w-full h-auto relative overflow-hidden rounded-lg max-w-xs group ">
                                  <img
                                    class="mx-auto w-full h-auto"
                                    src={mockup.mockup_image}
                                    alt=""
                                  />
                                </div>

                                <input
                                  type="checkbox"
                                  checked={selectedMockups.includes(
                                    mockup.mockup_id
                                  )}
                                  onChange={() =>
                                    toggleImageSelection(mockup.mockup_id)
                                  }
                                />
                                <span class="text-sm text-gray-900 dark:text-neutral-900">
                                  Template: {mockup.template}
                                </span>
                              </div>
                            </label>
                          ))}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
