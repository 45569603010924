import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import { ACCESS_TOKEN, REFRESH_TOKEN, CURRENT_USER, CURRENT_USER_FIRST_NAME, CURRENT_USER_LAST_NAME } from "../../constants";
import { toast, ToastContainer } from "react-toastify";
import Logo from "./Assets/Logo";
import Navbar from "./Navbar";
import LogoWhite from "./Assets/LogoWhite";
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const name = method === "login" ? "Login" : "Register";
  const route = "/api/token/";
  const method = "login";

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      const res = await api.post(route, { username, password });
      if (method === "login") {
        localStorage.setItem(CURRENT_USER, res.data.user.username);
        localStorage.setItem(CURRENT_USER_FIRST_NAME, res.data.user.first_name);
        localStorage.setItem(CURRENT_USER_LAST_NAME, res.data.user.last_name);
        localStorage.setItem(ACCESS_TOKEN, res.data.access);
        localStorage.setItem(REFRESH_TOKEN, res.data.refresh);
        toast.success("Login successful", {
          position: "top-right", // Correct way to set the position
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/account");
      } else {
        toast.error("Error occurred while logging in", {
          position: "top-right", // Correct way to set the position
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/login");
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    
<div>
      <Navbar />

      <section className="bg-white">
        <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
          <section className="relative flex h-16 items-center bg-gray-900 lg:col-span-5 lg:h-full xl:col-span-6">
            <img
              alt=""
              src="https://images.unsplash.com/photo-1665686310429-ee43624978fa?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              className="absolute inset-0 h-full w-full object-cover opacity-80"
            />

            <div className="hidden lg:relative lg:block lg:p-12">
              <a href="/">
                <LogoWhite />
              </a>

              <h2 className="mt-6 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                Your journey begins here!
              </h2>

              


            </div>
          </section>
          <span class="absolute bottom-0 w-full  text-left text-white p-2 text-xs ">
          Photo by <a href="https://unsplash.com/@microsoftedge?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Microsoft Edge</a> on <a href="https://unsplash.com/photos/surface-pro-9-6CNB3iD8M4E?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>


    
  



  </span>
          <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
            <div className="max-w-xl lg:max-w-3xl">
              <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in to your account
                </h2>
              </div>

              <form
                className=" mt-8 grid grid-cols-6 gap-6"
                action="#"
                method="POST"
                onSubmit={handleSubmit}
              >



                <div className="col-span-6">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>

                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="col-span-6 ">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>

                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <div className="text-sm text-right">
                  <a
                    href="/forgot-password"
                    className="font-semibold text-indigo-600 hover:text-indigo-500 "
                  >
                    Forgot password?
                  </a>
                </div>

                </div>



                <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                  <button
                    type="submit"
                    className="inline-block shrink-0 rounded-md border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
                  >
                    Sign In
                  </button>
                  <ToastContainer />



                  <p className=" text-center text-sm text-gray-500">
            Not a member?{" "}
            <a
              href="/register"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Register Now
            </a>
          </p>


                </div>
              </form>
            </div>
          </main>
        </div>
      </section>
    </div>


  );
};

export default Login;
