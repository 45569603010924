import React from "react";
import "./custom.css"; // Import the CSS file

import BannerBackground from "./Assets/home-banner-background.png";
import BannerImage from "./Assets/home-banner-image.png";
import WorkflowImage from "./Assets/Workflow.png";
import Navbar from "./Navbar";
import Features from "./Features";
import Footer from "./Footer";
import Testimonial from "./Testimonial";
import HowItWorks from "./HowItWorks";
import Hero from "./Hero";
import Pricing from "./blocks/Pricing";

import { FiArrowRight } from "react-icons/fi";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Features />
      <HowItWorks />
      <Pricing />
      {/* <Testimonial /> */}
      <Footer />
    </div>
  );
};

export default Home;
