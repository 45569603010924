// Header.js
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { CheckIcon, ArrowSmallRightIcon } from "@heroicons/react/24/outline"; // Updated import paths for Heroicons v2
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FaGripfire } from "react-icons/fa";
import { isAuthenticated } from "./../website/blocks/auth";
import { useNavigate } from "react-router-dom";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CURRENT_USER,
  CURRENT_WORKSPACE,
  SERVER_BASE_URL,
} from "./../../constants";
// import Logo from "./Assets/Logo.png"
import Logo from "./../website/Assets/Logo";
import { IoCaretBackOutline } from "react-icons/io5";

const steps = [
  { name: "Select Workspace", url: "/welcome" },
  { name: "Prepare Designs", url: "/prepareDesigns" },
  { name: "Update Metadata", url: "/updateMetadata" },
  // { name: "Generate AI Content", url: "/generate-ai-content" },
  { name: "Select Mockup Templates", url: "/selectMockupTemplates" },
  { name: "Generate Mockups", url: "/generateMockups" },
  { name: "Review Mockups", url: "/reviewMockups" },
  // { name: "Select Printify Template", url: "/select-printify-template" },
  { name: "Create Printify Listings", url: "/createPrintifyListings" },
  { name: "Publish Listings", url: "/publishListings" },
  // { name: "Combined Create Listings", url: "/createListings" },
];

function Header() {
  const location = useLocation();
  const currentPath = location.pathname;
  const [openMenu, setOpenMenu] = useState(false);
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();

  // Determine the current step based on the URL path
  const currentStep = steps.findIndex((step) => currentPath === step.url);

  useEffect(() => {
    setAuth(isAuthenticated());
  }, []);

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    setAuth(false);
    navigate("/login");
  };

  return (
    <div className="w-full bg-white shadow-md z-50 sticky top-0">
      <div className="container mx-auto flex justify-between items-center p-4 ">
        <Logo />
        <div className="navbar-links-container flex space-x-8  ">
          <a href="/">Home</a>
          <a href="/resources">Resources</a>
          <a href="/contactus">Contact Us</a>

          {auth ? (
            <>
              <a href="/account">Your Account</a>
              <a href="#" onClick={handleLogout}>
                Logout
              </a>
              <a
                href="/welcome"
                className=" bg-indigo-700 hover:bg-indigo-900  px-2 rounded"
              >
                <span class="text-white font-bold">Your Workspaces</span>
              </a>
            </>
          ) : (
            <>
              <a href="/login">Login</a>
              <a href="/register">Register</a>
            </>
          )}
        </div>
      </div>
      <div>
        <div class="bg-gray-900 hidden">
          <div class="flex w-full h-full items-center justify-center p-1">
            <div class="text-center">
              <span class="text-gray-50 text-xl font-bold">
                * * * POD Express Workbench * * *
              </span>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-between items-center p-2 bg-gray-800">
          {steps.map((step, index) => {
            const isCompleted = index < currentStep;
            const isCurrent = index === currentStep;

            return (
              <div key={index} className="flex flex-col items-center">
                <Link
                  to={step.url}
                  className={`flex items-center justify-center rounded-full ${isCompleted ? "bg-gray-800 text-white" : isCurrent ? "bg-gray-800 text-white" : "bg-gray-300 text-black"} transition duration-300 ease-in-out`}
                >
                  {isCompleted ? (
                    <IoIosCheckmarkCircle className="h-5 w-5" />
                  ) : (
                    <FaArrowAltCircleRight
                      className={`h-5 w-5 ${isCurrent ? "text-white" : "text-white"}`}
                    />
                  )}
                </Link>
                <span
                  className={`mt-2 text-s ${isCompleted || isCurrent ? "text-white" : "text-gray-100"}`}
                >
                  {step.name}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Header;
