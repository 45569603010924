import React from 'react';
import { RiRobot3Fill } from "react-icons/ri";

const LogoWhite = () => {


  return (
      <div className="nav-logo-container flex items-center space-x-4">
      <RiRobot3Fill size={64} color="white" />
      <div className="flex flex-col font-kalam">
        <h1 className=" text-gray-100 text-2xl font-bold">EasemyPOD</h1>
        <p className="text-gray-100">Your Listings Assistant</p>
      </div>
    </div>
    
);
};

export default LogoWhite;
