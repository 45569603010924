import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../api";
import { useNavigate } from "react-router-dom"; // Import useHistory hook
import { Link } from "react-router-dom";
import CreateWorkspaceModal from "./blocks/CreateWorkspaceModal";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CURRENT_USER,
  CURRENT_WORKSPACE,
} from "../../constants";
import { PiFolderSimplePlusLight, PiFolderSimpleLight } from "react-icons/pi";
import { BsFolderPlus } from "react-icons/bs";
import { FaMinusCircle } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import the CSS
import { DotLoader } from "react-spinners";


const Welcome = () => {
  const [workspaces, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [loading, setLoading] = useState(false); // Add state for loading
  const navigate = useNavigate();

  // Function to handle click event
  const handleClick = (workspace_id) => {
    localStorage.setItem(CURRENT_WORKSPACE, workspace_id);

    // Construct the URL with the workspace_id as a query parameter
    const url = `/prepareDesigns`; // this was workarea before, I changed it to prepareDesigns

    // Navigate to the constructed URL
    navigate(url);
  };

  // Function to fetch data from the server
  const fetchData = async () => {
    try {
      const options = {
        method: "GET",
      };

      try {
        const response = await api.get("/workspace/get_workspaces/");
        const data = response.data;
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        alert(error.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Function to handle form submission
  const handleSubmit = async (inputValue) => {
    try {
      // Make a POST request to send data to the server
      await api.post("/workspace/create_workspace/", {
        workspace_name: inputValue,
      });
      // After successful POST, fetch updated data
      closeModal();
      fetchData();
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };

    const handleWorkspaceDeleteOld = async (inputValue) => {
      try {
        // Make a POST request to send data to the server
        await api.post("/workspace/delete_workspace/", {
          workspace_name: inputValue,
        });
        // After successful POST, fetch updated data
        fetchData();
        alert("workspace deleted successfully");
      } catch (error) {
        console.error("Error adding data:", error);
      }
    };




    const handleWorkspaceDelete = async (inputValue) => {
  
      // Show confirmation dialog
      confirmAlert({
        title: "Confirm to delete workspace",
        message:
          "Are you sure you want to delete this workspace? This operation is irreversible and all your data in this workspace will be deleted permanantly.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              setLoading(true);

              try {
                // Submit form data to the backend
                await api.delete(`/workspace/delete_workspace/${inputValue}/`);
                fetchData();
              } catch (error) {
                console.error("Error submitting form data:", error);
              } finally {
                setLoading(false);
              }
            },
          },
          {
            label: "No",
            onClick: () => {
              // Do nothing if the user clicks 'No'
            },
          },
        ],
      });
    };





  // Fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div>
        <CreateWorkspaceModal
          isOpen={isOpen}
          onRequestClose={closeModal}
          onSubmit={handleSubmit}
        />
      </div>

      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <DotLoader size={100} color="#6D85EC" />
        </div>
      )}

      <div className="container px-5 py-5 mx-auto ">
        <div className="flex flex-wrap justify-center items-center -m-4 text-center font-karla">
          <p className="p-5 text-left text-4xl font-semibold">
            Your Workspaces
          </p>
          <p className="p-5 text-left text-lg ">
            Think of a workspace as your personal design organizer. It functions
            like virtual folders where you can categorize your designs into
            logical groups for easy access and management. Whether you’re
            handling multiple projects or creating design batches, workspaces
            help you stay organized and productive. They streamline your
            workflow, making it simple to manage and collaborate on your
            projects. You can either create a new workspace or select an
            existing one to get started.
          </p>
          <p className="p-2 title-font font-medium text-xl text-gray-900">
            Choose a workspace or create a new one <hr />
          </p>
        </div>

        <div className="flex flex-wrap justify-center  -m-4 text-center">
          <div onClick={openModal}>
            <div className="p-4">
              <div className="bg-yellow-200 hover:bg-yellow-300 w-32 h-32 transform transition duration-300 hover:scale-110 cursor-pointer flex flex-col justify-center items-center">
                <div className="mb-2">
                  <PiFolderSimplePlusLight style={{ fontSize: "48px" }} />
                </div>
                <div>
                  <h2>New Workspace</h2>
                </div>
              </div>
            </div>
          </div>

          {workspaces.length > 0 ? (
            workspaces.map((workspace) => (
              <div
                className=""
              >
                <div className="p-4">
                  <div 
                  className="bg-blue-200 hover:bg-blue-300 w-32 h-32 transform transition duration-300 hover:scale-110 cursor-pointer flex flex-col justify-center items-center"
                  onClick={() => handleClick(workspace.workspace_id)}
                  >
                    <div>
                      <PiFolderSimpleLight style={{ fontSize: "48px" }} />
                    </div>
                    <div>
                      <h2>
                        <a href="#">{workspace.workspace_name}</a>
                      </h2>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center p-2">
                    <div>
                      <FaMinusCircle
                        style={{
                          fontSize: "20px",
                          color: "#C70039",
                          cursor: "pointer",
                        }}
                        onClick={() => handleWorkspaceDelete(workspace.workspace_id)} 
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (

                <div className="p-4">
                  <div 
                  className=" w-32 h-32 flex flex-col justify-center items-center"
                  >
                    <p>No workspaces available</p>
                  </div>

                </div>

          )}
        </div>
      </div>
    </div>
  );
};

export default Welcome;
